import {useEffect} from 'react'
import {Navigate, Outlet} from 'react-router-dom'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {routes} from '@utilities/constants'

const DEFAULT_LOGGED_PATH = routes.PHOTOSHOOT_REQUEST.path

export const PublicRoute = () => {
    const accessToken = useAuthStore(state => state.accessToken)
    const origin = localStorage.getItem('elevate-login-origin')
    let loggedNavigationPath = DEFAULT_LOGGED_PATH

    if (accessToken && origin) {
        switch (origin) {
            case routes.CATEGORIES.path: {
                loggedNavigationPath = routes.CATEGORIES.path
                break
            }
            case routes.CO_ALARM_DASHBOARD.path: {
                loggedNavigationPath = routes.CO_ALARM_DASHBOARD.path
                break
            }
            default: {
                loggedNavigationPath = DEFAULT_LOGGED_PATH
            }
        }
    }

    useEffect(() => {
        return () => {
            origin && localStorage.removeItem('elevate-login-origin')
        }
    }, [origin])

    return accessToken && loggedNavigationPath ? <Navigate to={loggedNavigationPath} /> : <Outlet />
}

PublicRoute.displayName = 'PublicRoute'
