import {useTranslation} from 'react-i18next'
import {
    StyledCategoriesRequestBody,
    StyledCategoriesRequestFooter,
    StyledCategoriesRequestHeader,
    StyledCategoryRemoveInfo,
    StyledCategoryRemoveReason,
    StyledRemoveIconWrapper,
    StyledSuccessRequestToast
} from './style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useCategories} from '../services/queries/useCategories'
import {useCategoriesRequestStore} from '../store/CategoriesRequestStore'
import {CategoryChip} from '../category-chip/CategoryChip'
import {FC, useMemo, useRef} from 'react'
import {Category} from '../types'
import {CategoriesDictionary, CategoryDictionaryKey} from '../utils'
import Button from '@/components/ui/button/Button'
import {XCircleIcon} from '@/components/ui/icon'
import toast from 'react-hot-toast'
import {errorHandler} from '@/utilities/helpers'
import {useRemoveCategory} from '../services/queries/useRemoveCategory'
import Spinner from '@components/ui/spinner/Spinner.tsx'

interface RemoveCategoriesRequestProps {
    listingId: string
    hostCode: number
    hostPassword: string
}

export const RemoveCategoryRequest: FC<RemoveCategoriesRequestProps> = ({listingId, hostCode, hostPassword}) => {
    const {data: categories} = useCategories()
    const {t} = useTranslation()
    const selectedCategoryId = useCategoriesRequestStore(store => store.selectedCategoryIds)[0]
    const toggleCategoriesModal = useCategoriesRequestStore(store => store.toggleModalVisibility)
    const reasonRef = useRef<HTMLTextAreaElement | null>(null)

    const {mutate: removeCategory, isPending: isPendingRemoveCategory} = useRemoveCategory(
        {
            hostCode,
            hostPassword,
            listingId,
            categoryId: selectedCategoryId
        },
        {
            onSuccess: () => {
                toast.success(
                    () => (
                        <StyledSuccessRequestToast>
                            <h5>{t('categories:toasts:success_remove_title')}</h5>
                            <p>{t('categories:toasts:success_remove_description')}</p>
                        </StyledSuccessRequestToast>
                    ),
                    {duration: 4500}
                )
                toggleCategoriesModal()
            },
            onError: errorHandler
        }
    )

    const onRemoveCategoryRequest = () => {
        removeCategory({
            reason: reasonRef.current?.value
        })
    }

    const category = useMemo<Category>(
        () => categories.find(({id}) => id === selectedCategoryId) as Category,
        [selectedCategoryId]
    )

    return (
        <>
            <StyledCategoriesRequestHeader direction="column" gap={6}>
                <Flexbox gap={2} direction="column" width={'100%'}>
                    <StyledRemoveIconWrapper align="center" justify="center">
                        <XCircleIcon />
                    </StyledRemoveIconWrapper>

                    <h3>{t('categories:modals:remove:title')}</h3>
                    <p>{t('categories:modals:remove:subtitle')}</p>
                </Flexbox>
            </StyledCategoriesRequestHeader>

            <StyledCategoriesRequestBody>
                <Flexbox direction="column" gap={6} width={'100%'}>
                    <CategoryChip
                        id={category.id}
                        label={t(CategoriesDictionary[category.name as CategoryDictionaryKey]?.label) ?? category.name}
                        icon={CategoriesDictionary[category.name as CategoryDictionaryKey]?.icon}
                    />

                    <StyledCategoryRemoveReason
                        ref={reasonRef}
                        rows={3}
                        label={t('categories:modals:remove:textarea_label')}
                        placeholder={t('categories:modals:remove:textarea_placeholder')}
                        disabled={isPendingRemoveCategory}
                    />

                    <StyledCategoryRemoveInfo>{t('categories:modals:remove:info')}</StyledCategoryRemoveInfo>
                </Flexbox>
            </StyledCategoriesRequestBody>

            <StyledCategoriesRequestFooter justify="space-between">
                <Button variant="tertiary" onClick={() => toggleCategoriesModal()} disabled={isPendingRemoveCategory}>
                    {t('commons:close')}
                </Button>
                <Button variant="primary" disabled={isPendingRemoveCategory} onClick={onRemoveCategoryRequest}>
                    {isPendingRemoveCategory && <Spinner size={24} />}
                    {t('categories:modals:remove:btn')}
                </Button>
            </StyledCategoriesRequestFooter>
        </>
    )
}
