import {ReactNode} from 'react'
import a_frames from '@assets/images/categories/icons/a_frames.jpg'
import adapted from '@assets/images/categories/icons/adapted.jpg'
import amazing_pools from '@assets/images/categories/icons/amazing_pools.jpg'
import amazing_views from '@assets/images/categories/icons/amazing_views.jpg'
import arctic from '@assets/images/categories/icons/arctic.jpg'
import barns from '@assets/images/categories/icons/barns.jpg'
import beach from '@assets/images/categories/icons/beach.jpg'
import beachfront from '@assets/images/categories/icons/beachfront.jpg'
import b_n_b from '@assets/images/categories/icons/b&b.jpg'
import boats from '@assets/images/categories/icons/boats.jpg'
import cabins from '@assets/images/categories/icons/cabins.jpg'
import campers from '@assets/images/categories/icons/campers.jpg'
import camping from '@assets/images/categories/icons/camping.jpg'
import casas_particulares from '@assets/images/categories/icons/casas_particulares.jpg'
import castles from '@assets/images/categories/icons/castles.jpg'
import caves from '@assets/images/categories/icons/caves.jpg'
import chefs_kitchens from '@assets/images/categories/icons/chefs_kitchens.jpg'
import containers from '@assets/images/categories/icons/containers.jpg'
import countryside from '@assets/images/categories/icons/countryside.jpg'
import creative_spaces from '@assets/images/categories/icons/creative_spaces.jpg'
import cycladic_homes from '@assets/images/categories/icons/cycladic_homes.jpg'
import dammusi from '@assets/images/categories/icons/dammusi.jpg'
import desert from '@assets/images/categories/icons/desert.jpg'
import design from '@assets/images/categories/icons/design.jpg'
import domes from '@assets/images/categories/icons/domes.jpg'
import earth_homes from '@assets/images/categories/icons/earth_homes.jpg'
import farms from '@assets/images/categories/icons/farms.jpg'
import golfing from '@assets/images/categories/icons/golfing.jpg'
import grand_pianos from '@assets/images/categories/icons/grand_pianos.jpg'
import houseboats from '@assets/images/categories/icons/houseboats.jpg'
import hanoks from '@assets/images/categories/icons/hanoks.jpg'
import historical_homes from '@assets/images/categories/icons/historical_homes.jpg'
import iconic_cities from '@assets/images/categories/icons/iconic_cities.jpg'
import islands from '@assets/images/categories/icons/islands.jpg'
import {CategoryKezhan} from '@/components/ui/icon'
import lake from '@assets/images/categories/icons/lake.jpg'
import lakefront from '@assets/images/categories/icons/lakefront.jpg'
import luxe from '@assets/images/categories/icons/luxe.jpg'
import mansions from '@assets/images/categories/icons/mansions.jpg'
import minsus from '@assets/images/categories/icons/minsus.jpg'
import national_parks from '@assets/images/categories/icons/national_parks.jpg'
import new_icon from '@assets/images/categories/icons/new.jpg'
import off_the_grid from '@assets/images/categories/icons/off_the_grid.jpg'
import omg from '@assets/images/categories/icons/omg.jpg'
import play from '@assets/images/categories/icons/play.jpg'
import riads from '@assets/images/categories/icons/riads.jpg'
import ryokans from '@assets/images/categories/icons/ryokans.jpg'
import shepherds_huts from '@assets/images/categories/icons/shepherds_huts.jpg'
import ski_in_out from '@assets/images/categories/icons/ski_in_out.jpg'
import skiing from '@assets/images/categories/icons/skiing.jpg'
import surfing from '@assets/images/categories/icons/surfing.jpg'
import tiny_homes from '@assets/images/categories/icons/tiny_homes.jpg'
import top_of_the_world from '@assets/images/categories/icons/top_of_the_world.jpg'
import towers from '@assets/images/categories/icons/towers.jpg'
import trending from '@assets/images/categories/icons/trending.jpg'
import treehouses from '@assets/images/categories/icons/treehouses.jpg'
import tropical from '@assets/images/categories/icons/tropical.jpg'
import trulli from '@assets/images/categories/icons/trulli.jpg'
import vineyards from '@assets/images/categories/icons/vineyards.jpg'
import windmills from '@assets/images/categories/icons/windmills.jpg'
import yurts from '@assets/images/categories/icons/yurts.jpg'
import rooms from '@assets/images/categories/icons/rooms.jpg'
import rareFinds from '@assets/images/categories/icons/rare_finds.png'

export type CategoryDictionaryValue = {
    label: string
    icon: ReactNode
}

export type CategoryDictionaryKey =
    | 'A-frames'
    | 'Adapted'
    | 'Amazing pools'
    | 'Amazing views'
    | 'Arctic'
    | 'Barns'
    | 'Beach'
    | 'Beachfront'
    | 'Bed & breakfast'
    | 'Boats'
    | 'Cabins'
    | 'Campers'
    | 'Camping'
    | 'Casas particulares'
    | 'Castles'
    | 'Caves'
    | "Chef's kitchens"
    | 'Containers'
    | 'Countryside'
    | 'Creative spaces'
    | 'Cycladic homes'
    | 'Dammusos'
    | 'Desert'
    | 'Design'
    | 'Domes'
    | 'Earth homes'
    | 'Farms'
    | 'Golfing'
    | 'Grand pianos'
    | 'Hanok'
    | 'Historical homes'
    | 'Houseboats'
    | 'Iconic cities'
    | 'Islands'
    | 'Kezhans'
    | 'Lake'
    | 'Lakefront'
    | 'Luxe'
    | 'Mansions'
    | 'Minsus'
    | 'National parks'
    | 'New'
    | 'Off-the-grid'
    | 'One-of-a-kind'
    | 'OMG!'
    | 'Play'
    | 'Rooms'
    | 'Riads'
    | 'Ryokans'
    | "Shepherd's huts"
    | 'Ski-in/out'
    | 'Skiing'
    | 'Surfing'
    | 'Tiny homes'
    | 'Top of the world'
    | 'Towers'
    | 'Trending'
    | 'Treehouses'
    | 'Tropical'
    | 'Trulli'
    | 'Vineyards'
    | 'Windmills'
    | 'Yurts'
    | 'Private room child'
    | 'Private rooms'
    | 'Rare find'
    | 'Kids amenities'
    | 'Themed kids room'

export const CategoriesDictionary = {
    'A-frames': {
        label: 'categories:labels:a_frames',
        icon: <img src={a_frames} />
    },
    Adapted: {
        label: 'categories:labels:adapted',
        icon: <img src={adapted} />
    },
    'Amazing pools': {
        label: 'categories:labels:amazing_pools',
        icon: <img src={amazing_pools} />
    },
    'Amazing views': {
        label: 'categories:labels:amazing_views',
        icon: <img src={amazing_views} />
    },
    Arctic: {
        label: 'categories:labels:arctic',
        icon: <img src={arctic} />
    },
    Barns: {
        label: 'categories:labels:barns',
        icon: <img src={barns} />
    },
    Beach: {
        label: 'categories:labels:beach',
        icon: <img src={beach} />
    },
    Beachfront: {
        label: 'categories:labels:beachfront',
        icon: <img src={beachfront} />
    },
    'Bed & breakfast': {
        label: 'categories:labels:b&b',
        icon: <img src={b_n_b} />
    },
    Boats: {
        label: 'categories:labels:boats',
        icon: <img src={boats} />
    },
    Cabins: {
        label: 'categories:labels:cabins',
        icon: <img src={cabins} />
    },
    Campers: {
        label: 'categories:labels:campers',
        icon: <img src={campers} />
    },
    Camping: {
        label: 'categories:labels:camping',
        icon: <img src={camping} />
    },
    'Casas particulares': {
        label: 'categories:labels:casas_particulares',
        icon: <img src={casas_particulares} />
    },
    Castles: {
        label: 'categories:labels:castles',
        icon: <img src={castles} />
    },
    Caves: {
        label: 'categories:labels:caves',
        icon: <img src={caves} />
    },
    "Chef's kitchens": {
        label: 'categories:labels:chefs_kitchens',
        icon: <img src={chefs_kitchens} />
    },
    Containers: {
        label: 'categories:labels:containers',
        icon: <img src={containers} />
    },
    Countryside: {
        label: 'categories:labels:countryside',
        icon: <img src={countryside} />
    },
    'Creative spaces': {
        label: 'categories:labels:creative_spaces',
        icon: <img src={creative_spaces} />
    },
    'Cycladic homes': {
        label: 'categories:labels:cycladic_homes',
        icon: <img src={cycladic_homes} />
    },
    Dammusos: {
        label: 'categories:labels:dammusos',
        icon: <img src={dammusi} />
    },
    Desert: {
        label: 'categories:labels:desert',
        icon: <img src={desert} />
    },
    Design: {
        label: 'categories:labels:design',
        icon: <img src={design} />
    },
    Domes: {
        label: 'categories:labels:domes',
        icon: <img src={domes} />
    },
    'Earth homes': {
        label: 'categories:labels:earth_homes',
        icon: <img src={earth_homes} />
    },
    Farms: {
        label: 'categories:labels:farms',
        icon: <img src={farms} />
    },
    Golfing: {
        label: 'categories:labels:golfing',
        icon: <img src={golfing} />
    },
    'Grand pianos': {
        label: 'categories:labels:grand_pianos',
        icon: <img src={grand_pianos} />
    },
    Hanok: {
        label: 'categories:labels:hanok',
        icon: <img src={hanoks} />
    },
    'Historical homes': {
        label: 'categories:labels:historical_homes',
        icon: <img src={historical_homes} />
    },
    Houseboats: {
        label: 'categories:labels:houseboats',
        icon: <img src={houseboats} />
    },
    'Iconic cities': {
        label: 'categories:labels:iconic_cities',
        icon: <img src={iconic_cities} />
    },
    Islands: {
        label: 'categories:labels:islands',
        icon: <img src={islands} />
    },
    Kezhans: {
        label: 'categories:labels:kezhans',
        icon: <CategoryKezhan />
    },
    Lake: {
        label: 'categories:labels:lake',
        icon: <img src={lake} />
    },
    Lakefront: {
        label: 'categories:labels:lakefront',
        icon: <img src={lakefront} />
    },
    Luxe: {
        label: 'categories:labels:luxe',
        icon: <img src={luxe} />
    },
    Mansions: {
        label: 'categories:labels:mansions',
        icon: <img src={mansions} />
    },
    Minsus: {
        label: 'categories:labels:minsus',
        icon: <img src={minsus} />
    },
    'National parks': {
        label: 'categories:labels:national_parks',
        icon: <img src={national_parks} />
    },
    New: {
        label: 'categories:labels:new',
        icon: <img src={new_icon} />
    },
    'Off-the-grid': {
        label: 'categories:labels:off_the_grid',
        icon: <img src={off_the_grid} />
    },
    'One-of-a-kind': {
        label: 'categories:labels:omg',
        icon: <img src={omg} />
    },
    'OMG!': {
        label: 'categories:labels:omg',
        icon: <img src={omg} />
    },
    Play: {
        label: 'categories:labels:play',
        icon: <img src={play} />
    },
    Riads: {
        label: 'categories:labels:riads',
        icon: <img src={riads} />
    },
    Rooms: {
        label: 'categories:labels:rooms',
        icon: <img src={rooms} />
    },
    Ryokans: {
        label: 'categories:labels:ryokans',
        icon: <img src={ryokans} />
    },
    "Shepherd's huts": {
        label: 'categories:labels:shepherds_huts',
        icon: <img src={shepherds_huts} />
    },
    'Ski-in/out': {
        label: 'categories:labels:ski_in_out',
        icon: <img src={ski_in_out} />
    },
    Skiing: {
        label: 'categories:labels:skiing',
        icon: <img src={skiing} />
    },
    Surfing: {
        label: 'categories:labels:surfing',
        icon: <img src={surfing} />
    },
    'Tiny homes': {
        label: 'categories:labels:tiny_homes',
        icon: <img src={tiny_homes} />
    },
    'Top of the world': {
        label: 'categories:labels:top_of_the_world',
        icon: <img src={top_of_the_world} />
    },
    Towers: {
        label: 'categories:labels:towers',
        icon: <img src={towers} />
    },
    Trending: {
        label: 'categories:labels:trending',
        icon: <img src={trending} />
    },
    Treehouses: {
        label: 'categories:labels:treehouses',
        icon: <img src={treehouses} />
    },
    Tropical: {
        label: 'categories:labels:tropical',
        icon: <img src={tropical} />
    },
    Trulli: {
        label: 'categories:labels:trulli',
        icon: <img src={trulli} />
    },
    Vineyards: {
        label: 'categories:labels:vineyards',
        icon: <img src={vineyards} />
    },
    Windmills: {
        label: 'categories:labels:windmills',
        icon: <img src={windmills} />
    },
    Yurts: {
        label: 'categories:labels:yurts',
        icon: <img src={yurts} />
    },
    'Private rooms': {
        label: 'categories:labels:private_rooms',
        icon: <img src={rooms} />
    },
    'Private room child': {
        label: 'categories:labels:private_rooms_child',
        icon: <img src={rooms} />
    },
    'Rare find': {
        label: 'categories:labels:rare_find',
        icon: <img src={rareFinds} />
    },
    'Kids amenities': {
        label: 'categories:labels:kids_amenities',
        icon: <img src={play} />
    },
    'Themed kids room': {
        label: 'categories:labels:themed_kids_room',
        icon: <img src={rooms} />
    }
} as const satisfies Record<CategoryDictionaryKey, CategoryDictionaryValue>
