import {CoAlarmRequest} from '@/features/co-alarm/types'
import {useTranslation} from 'react-i18next'
import {
    StyledCoAlarmEditRequestModal,
    StyledCoAlarmEditRequestModalBody,
    StyledCoAlarmEditRequestModalHeader
} from '@/features/co-alarm/components/co-alarm-edit-request-modal/style'
import {
    CO_ALARM_EDIT_REQUEST_MODEL,
    CoAlarmEditRequestSchema
} from '@/features/co-alarm/components/co-alarm-edit-request-modal/CoAlarmEditRequestFormModel'
import {
    StyledCoAlarmSendRequestInputsWrapper,
    StyledFullWidthInputText,
    StyledPhoneInputWrapper,
    StyledSelectCountry
} from '@/features/co-alarm/components/co-alarm-send-request-form/style'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import InputText from '@/components/commons/input-text/InputText'
import {InputHelpText} from '@/components/ui/input-help-text/InputHelpText'
import {Controller, useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import {ModalFooter} from '@/components/ui/modal-atoms/ModalAtoms'
import Button from '@/components/ui/button/Button'
import {useUpdateCoAlarmRequest} from '@/features/co-alarm/services/useUpdateCoAlarmRequest'
import toast from 'react-hot-toast'
import {StyledCoAlarmRequestSentMessage} from '@/features/co-alarm/components/co-alarm-send-request-form-wrapper/style'
import {zodResolver} from '@hookform/resolvers/zod'
import useAddressGeocoding from '@/features/co-alarm/hooks/useAddressGeocoding'
import {coAlarmRequestFormAdapter, parseManualAddressForm} from '@/features/co-alarm/utils'
import {SelectValue} from '@/components/commons/select/Select'
import {Label} from '@/components/ui/label/Label'
import {HttpUpdateCoAlarmRequestOptions} from '../../services/coAlarm.http'
import Spinner from '@/components/ui/spinner/Spinner'

export const CoAlarmEditRequestModal = ({
    coAlarmRequestDetails,
    hideModal
}: {
    coAlarmRequestDetails: CoAlarmRequest
    hideModal: () => void
}) => {
    const {
        t,
        i18n: {language}
    } = useTranslation()
    const {mutate: updateCoAlarmRequest, isPending: isPendingUpdateCoAlarmRequest} = useUpdateCoAlarmRequest({
        onSuccess: () => {
            toast.success(
                <StyledCoAlarmRequestSentMessage gap={1} direction="column">
                    <h4>{t('coAlarm:form:update_successfully_toast:title')}</h4>
                    <p>{t('coAlarm:form:update_successfully_toast:paragraph')}</p>
                </StyledCoAlarmRequestSentMessage>,
                {
                    position: 'top-right'
                }
            )

            hideModal()
        },
        onError: error => {
            console.error(error)
            toast.error(t('errors:default'), {
                position: 'top-right'
            })
        }
    })

    // Hook form setup
    const {
        control,
        handleSubmit,
        register,
        watch,
        formState: {errors, isValid, isSubmitting}
    } = useForm<CoAlarmEditRequestSchema>({
        mode: 'onBlur',
        shouldFocusError: true,
        resolver: zodResolver(CoAlarmEditRequestSchema),
        defaultValues: coAlarmRequestFormAdapter(coAlarmRequestDetails)
    })

    //Geocoder's utilities
    const {countriesLocalizedOptions} = useAddressGeocoding(language)

    // Watch form changes
    const countrySetted: CoAlarmEditRequestSchema['country'] = watch('country')

    // Form submit
    const onSubmit = handleSubmit(data => {
        updateCoAlarmRequest({
            urlParams: {
                listingId: coAlarmRequestDetails.listing.id,
                requestId: coAlarmRequestDetails.id
            },
            payload: HttpUpdateCoAlarmRequestOptions.shape['payload'].parse({
                ...data,
                ...parseManualAddressForm(data, countrySetted),
                manual_address: true,
                country: countrySetted.code
            }),
            params: {
                response_type: 'extended'
            }
        })
    })

    return (
        <StyledCoAlarmEditRequestModal width={500} onOverlayClick={() => !isPendingUpdateCoAlarmRequest && hideModal()}>
            <StyledCoAlarmEditRequestModalHeader>
                <h3>{t('coAlarm:edit_shipping_info_modal:title')}</h3>
            </StyledCoAlarmEditRequestModalHeader>
            <StyledCoAlarmEditRequestModalBody>
                <Flexbox id="edit-request" direction="column" as="form" gap={8} onSubmit={onSubmit} width="100%">
                    <StyledCoAlarmSendRequestInputsWrapper>
                        <InputText
                            label={t(CO_ALARM_EDIT_REQUEST_MODEL.firstName.label)}
                            type={'text'}
                            inputSize="sm"
                            errorMessage={t(errors.first_name?.message || '')}
                            placeholder={t(CO_ALARM_EDIT_REQUEST_MODEL.firstName.placeholder).toString()}
                            {...register(t(CO_ALARM_EDIT_REQUEST_MODEL.firstName.name))}
                            helpText={t(CO_ALARM_EDIT_REQUEST_MODEL.firstName.helpText)}
                            maxLength={50}
                        />
                        <InputText
                            label={t(CO_ALARM_EDIT_REQUEST_MODEL.lastName.label)}
                            type={'text'}
                            inputSize="sm"
                            errorMessage={t(errors.last_name?.message || '')}
                            placeholder={t(CO_ALARM_EDIT_REQUEST_MODEL.lastName.placeholder).toString()}
                            {...register(t(CO_ALARM_EDIT_REQUEST_MODEL.lastName.name))}
                            helpText={t(CO_ALARM_EDIT_REQUEST_MODEL.lastName.helpText)}
                            maxLength={50}
                        />
                    </StyledCoAlarmSendRequestInputsWrapper>

                    <Flexbox direction="column" gap={1} width="100%">
                        <Controller
                            render={({field: {onChange, value}}) => (
                                <StyledSelectCountry
                                    key={`lang-${language}`}
                                    value={value as SelectValue}
                                    onChange={newValue => {
                                        onChange(newValue as SelectValue)
                                    }}
                                    size={'medium'}
                                    name={CO_ALARM_EDIT_REQUEST_MODEL.country.name}
                                    label={t(CO_ALARM_EDIT_REQUEST_MODEL.country.label)}
                                    isSearchable={true}
                                    errorMessage={t(errors.country?.message || '')}
                                    placeholder={t(CO_ALARM_EDIT_REQUEST_MODEL.country.placeholder)}
                                    options={countriesLocalizedOptions}
                                />
                            )}
                            control={control}
                            name={'country'}
                        />
                        <InputHelpText helpText={t(CO_ALARM_EDIT_REQUEST_MODEL.country.helpText)} />
                    </Flexbox>

                    <Controller
                        render={({field: {onChange, value}}) => (
                            <StyledPhoneInputWrapper direction="column" gap={2}>
                                <Label htmlFor={CO_ALARM_EDIT_REQUEST_MODEL.phone.name}>
                                    {t(CO_ALARM_EDIT_REQUEST_MODEL.phone.label)}
                                </Label>
                                <PhoneInput
                                    country={countrySetted.code}
                                    countryCodeEditable={false}
                                    placeholder={t(CO_ALARM_EDIT_REQUEST_MODEL.phone.placeholder)}
                                    value={value}
                                    onChange={(prefix: string) => {
                                        onChange(prefix)
                                    }}
                                    enableAreaCodes={false}
                                />
                                <InputHelpText error={t(errors.phone?.message || '')} />
                            </StyledPhoneInputWrapper>
                        )}
                        control={control}
                        name={'phone'}
                    />

                    <StyledFullWidthInputText
                        inputSize="sm"
                        label={t(CO_ALARM_EDIT_REQUEST_MODEL.firstAddressLine.label)}
                        type={'text'}
                        errorMessage={t(errors.address_line_1?.message || '')}
                        placeholder={t(CO_ALARM_EDIT_REQUEST_MODEL.firstAddressLine.placeholder).toString()}
                        maxLength={100}
                        {...register(t(CO_ALARM_EDIT_REQUEST_MODEL.firstAddressLine.name))}
                    />

                    <StyledFullWidthInputText
                        inputSize="sm"
                        label={t(CO_ALARM_EDIT_REQUEST_MODEL.secondAddressLine.label)}
                        type={'text'}
                        errorMessage={t(errors.address_line_2?.message || '')}
                        placeholder={t(CO_ALARM_EDIT_REQUEST_MODEL.secondAddressLine.placeholder).toString()}
                        maxLength={100}
                        {...register(t(CO_ALARM_EDIT_REQUEST_MODEL.secondAddressLine.name))}
                    />

                    <StyledFullWidthInputText
                        inputSize="sm"
                        label={t(CO_ALARM_EDIT_REQUEST_MODEL.city.label)}
                        type={'text'}
                        errorMessage={t(errors.city?.message || '')}
                        placeholder={t(CO_ALARM_EDIT_REQUEST_MODEL.city.placeholder).toString()}
                        maxLength={100}
                        {...register(t(CO_ALARM_EDIT_REQUEST_MODEL.city.name))}
                    />
                    <StyledFullWidthInputText
                        inputSize="sm"
                        label={t(CO_ALARM_EDIT_REQUEST_MODEL.state.label)}
                        type={'text'}
                        errorMessage={t(errors.state?.message || '')}
                        placeholder={t(CO_ALARM_EDIT_REQUEST_MODEL.state.placeholder).toString()}
                        maxLength={100}
                        {...register(t(CO_ALARM_EDIT_REQUEST_MODEL.state.name))}
                    />
                    <StyledFullWidthInputText
                        inputSize="sm"
                        label={t(CO_ALARM_EDIT_REQUEST_MODEL.postalCode.label)}
                        type={'text'}
                        errorMessage={t(errors.postal_code?.message || '')}
                        placeholder={t(CO_ALARM_EDIT_REQUEST_MODEL.postalCode.placeholder).toString()}
                        maxLength={20}
                        {...register(t(CO_ALARM_EDIT_REQUEST_MODEL.postalCode.name))}
                    />
                    <StyledFullWidthInputText
                        inputSize="sm"
                        label={t(CO_ALARM_EDIT_REQUEST_MODEL.addressExtras.label)}
                        type={'text'}
                        placeholder={t(CO_ALARM_EDIT_REQUEST_MODEL.addressExtras.placeholder).toString()}
                        maxLength={100}
                        {...register(t(CO_ALARM_EDIT_REQUEST_MODEL.addressExtras.name))}
                    />
                </Flexbox>
            </StyledCoAlarmEditRequestModalBody>
            <ModalFooter justify="space-between">
                <Button
                    variant="tertiary"
                    disabled={isSubmitting || isPendingUpdateCoAlarmRequest}
                    onClick={() => !isPendingUpdateCoAlarmRequest && hideModal()}
                >
                    {t('commons:close')}
                </Button>
                <Button
                    disabled={!isValid || isSubmitting || isPendingUpdateCoAlarmRequest}
                    variant="primary"
                    form="edit-request"
                    type="submit"
                >
                    {t('commons:confirm')}
                    {isPendingUpdateCoAlarmRequest && <Spinner size={12} />}
                </Button>
            </ModalFooter>
        </StyledCoAlarmEditRequestModal>
    )
}
