import {ModalBody, ModalHeader} from '@/components/ui/modal-atoms/ModalAtoms'
import {Modal} from '@/components/ui/modal/Modal'
import styled, {css} from 'styled-components'

export const StyledCoAlarmEditRequestModal = styled(Modal)`
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
`

export const StyledCoAlarmEditRequestModalHeader = styled(ModalHeader)(
    ({theme: {typography, spacing}}) => css`
        grid-template-columns: 1fr;
        text-align: left;
        padding: ${spacing * 6}px;

        & > h3 {
            ${typography.textXl};
        }
    `
)

export const StyledCoAlarmEditRequestModalBody = styled(ModalBody)(
    ({theme: {spacing}}) => css`
        padding: 0 ${spacing * 6}px ${spacing * 6}px ${spacing * 6}px;
    `
)
