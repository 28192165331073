import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledOtpStepWrapper = styled(Flexbox)(
    () => css`
        width: 100%;
    `
)

export const StyledOtpStepHeader = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        width: 100%;
        & > h6 {
            ${typography.textMd};
            color: ${palette.neutral['900']};
            font-weight: 700;
        }
    `
)

export const StyledOtpStepBody = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        & > p {
            ${typography.textSm};
            color: ${palette.neutral['900']};
            font-weight: 400;
            & > span {
                font-weight: 500;
            }
        }
    `
)

export const StyledOtpStepForm = styled.form(
    ({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: ${spacing * 4}px;
    `
)

export const StyledPhoneInputWrapper = styled.div`
    ${({theme: {spacing, palette, typography, shadows}}) => css`
        display: flex;
        flex-direction: column;
        gap: 6px;

        .react-tel-input > input {
            ${typography.textMd}
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${spacing}px;
            border: 1px solid ${palette.neutral['300']};
            width: 100%;
            transition: ease-in-out 200ms;
            cursor: pointer;
            height: 44px;
            padding: 0 ${spacing * 9}px 0 ${spacing * 12}px;

            &:not(:disabled):hover {
                border: 1px solid ${palette.primary['200']};
                box-shadow: ${`${shadows.xs}, 0px 0px 0px 4px ${palette.primary['25']}`};
            }

            &:focus {
                outline: none;
                border-color: ${palette.neutral['300']};
                box-shadow: unset;
            }

            &::placeholder {
                color: ${palette.neutral['400']};
            }

            &:disabled {
                cursor: not-allowed;
            }
        }

        .react-tel-input .country-list {
            width: 260px;
            position: fixed;
            max-height: 150px;
            margin: 0 auto;
            border-radius: 8px;
            overflow-x: hidden;

            &::-webkit-scrollbar {
                width: 4px;
                background-color: ${palette.neutral[50]};
            }

            &::-webkit-scrollbar-thumb {
                background-color: ${palette.neutral[300]};
                border-radius: 3px;

                &:hover {
                    background-color: ${palette.neutral[400]};
                }
            }
        }
    `}
`

export const StyledOtpInput = styled.input(
    ({theme: {spacing, typography, palette}}) => css`
        width: 64px;
        height: 64px;
        padding: ${spacing}px ${spacing * 2}px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 1px solid ${palette.neutral['300']};
        font-weight: 600;
        text-align: center;
        ${typography.displayMd};
        color: ${palette.neutral['900']};

        &:disabled {
            background: ${palette.neutral['100']};
            color: ${palette.neutral['400']};
            cursor: not-allowed;
        }
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        /* Firefox */

        &[type='number'] {
            -moz-appearance: textfield;
        }
    `
)

export const StyledSendAgainLink = styled.span<{isPending: boolean}>(
    ({isPending, theme: {typography, palette}}) => css`
        ${typography.textSm};
        pointer-events: ${isPending ? 'none' : 'auto'};
        text-decoration: underline;
        cursor: ${isPending ? 'not-allowed' : 'pointer'};
        color: ${isPending ? palette.neutral['400'] : palette.neutral['900']};
        font-weight: 700;
    `
)

export const StyledSmsOptBody = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        & > p {
            ${typography.textSm};
            color: ${palette.neutral['900']};
            font-weight: 400;
            & > span {
                font-weight: 500;
            }
        }
    `
)
