import {QueryClient, UseInfiniteQueryResult} from '@tanstack/react-query'

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'online',
            retry: 1,
            retryOnMount: true,
            retryDelay: 1000,
            staleTime: 600000, //10 min
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true
        }
    }
})

export const QUERY_KEYS = {
    AMENITIES_AUTOCOMPLETE: 'amenities-autocomplete',
    ACCESSIBILITY_ROOMS: 'accessibility-rooms',
    APPROVAL_REQUEST_DETAILS: 'approval-request-details',
    APPROVAL_REQUESTS_LIST: 'approval-requests-list',
    CATEGORIES: 'categories',
    CATEGORIES_AVAILABILITY: 'categories-availability',
    CO_ALARM_HOST_INFO: 'co-alarm-host-info',
    CO_ALARM_LISTINGS: 'co-alarm-listings',
    CO_ALARM_REQUEST_DETAILS: 'co-alarm-request-details',
    IN_PROGRESS_SERVICES: 'in-progress-services',
    LISTINGS_CATEGORIES: 'listings-categories',
    MATTERPORT_INFO: 'matterport-info',
    PHOTO_SERVICE_REQUESTS: 'photo-service-requests',
    ROOMS_AUTOCOMPLETE: 'rooms-autocomplete',
    SERVICE_DETAILS: 'service-details',
    SERVICES_PROPOSALS: 'services-proposals',
    SERVICE_CANCELLATION: 'service-cancellation-reasons',
    SERVICE_CANCELLATION_REASONS: 'service-cancellation-reasons',
    SERVICE_DECLINE_REASONS: 'service-decline-reasons'
} as const

export const MUTATION_KEYS = {
    SEND_REVIEWED_APPROVAL: 'send-approval-review',
    ACCEPT_QUOTE: 'accept-quote',
    ACCEPT_REQUESTED_SERVICE: 'accept-requested-service',
    ACCEPT_PORTRAIT: 'accept-portrait',
    ADD_CATEGORIES: 'add-categories',
    DECLINE_QUOTE: 'decline-quote',
    EDIT_PHONE_NUMBER: 'edit-phone-number',
    NOTIFICATION_PERMISSION: 'notification-permission',
    NOTIFICATION_PREFERENCES: 'notification-preferences',
    REMOVE_CATEGORY: 'remove-category',
    REQUEST_CO_ALARM: 'request-co-alarm',
    SEND_CO_ALARM_REQUEST: 'send-co-alarm-request',
    EDIT_CO_ALARM_REQUEST: 'edit-co-alarm-request',
    SERVICE_CANCELLATION: 'service-cancellation',
    SEND_FEEDBACK: 'send-feedback',
    SEND_HOST_DATES: 'send-host-dates',
    SERVICE_RESOURCES_LOG: 'service-resources-log',
    VALIDATE_OTP_CODE: 'validate-otp-code'
} as const

//rq-helpers
export const infiniteQueryFetchNextPage = async (infiniteQuery: UseInfiniteQueryResult) => {
    if (infiniteQuery.hasNextPage && !infiniteQuery.isFetching) {
        await infiniteQuery.fetchNextPage()
    }
}
