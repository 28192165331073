import {HOME_IMG_PLACEHOLDER_LG} from '@/utilities/constants'
import {HTMLAttributes, ImgHTMLAttributes} from 'react'
import {
    StyledListingThumbnailImage,
    StyledListingThumbnailName,
    StyledListingThumbnailParagraph
} from '@/components/ui/listing-thumbnail-atoms/style'

export const ListingThumbnailImage = (
    props: Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {src: string | null}
) => <StyledListingThumbnailImage {...props} src={props.src ?? HOME_IMG_PLACEHOLDER_LG} />

export const ListingThumbnailName = (props: HTMLAttributes<HTMLHeadingElement>) => (
    <StyledListingThumbnailName {...props} />
)

export const ListingThumbnailParagraph = (props: HTMLAttributes<HTMLParagraphElement>) => (
    <StyledListingThumbnailParagraph {...props} />
)
