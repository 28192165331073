import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {Image03Icon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from 'react-i18next'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {formatLocaleDate} from '@utilities/helpers.ts'
import dayjs from 'dayjs'
import {FC} from 'react'
import {Assignment} from '@/features/service/types.ts'

export const HomePhotoshootCompletedStatusDescription: FC<{assignmentDate: Assignment['to_shoot_at'] | undefined}> = ({
    assignmentDate
}) => {
    const {t} = useTranslation()
    //TODO: restore the additional days from 21 to 8 after M1
    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <Image03Icon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:home:photoshoot_completed_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:home:photoshoot_completed_paragraph1', {
                        uploadDate: formatLocaleDate(dayjs(assignmentDate).add(14, 'days'))
                    })}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

HomePhotoshootCompletedStatusDescription.displayName = 'HomePhotoshootCompletedStatusDescription'
