import styled, {css, DefaultTheme} from 'styled-components'
import * as RTooltip from '@radix-ui/react-tooltip'
import {CSSProperties} from 'react'

export const StyledTooltipContent = styled(RTooltip.Content)<{
    variant: 'light' | 'dark'
    $maxWidth: CSSProperties['maxWidth']
}>`
    ${({theme: {spacing, typography, shadows, zIndex}, $maxWidth}) => css`
        padding: ${spacing * 2}px ${spacing * 3}px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        box-shadow: ${shadows.lg};
        ${typography.textXs};
        font-weight: 600;
        max-width: ${$maxWidth};
        z-index: ${zIndex.tooltip};
    `}
    ${({variant, theme}) => ColorStyles(theme)[variant]};
`

const ColorStyles = ({palette}: DefaultTheme) => ({
    light: css`
        background: ${palette.neutral.white};
        color: ${palette.neutral['700']};
        & svg {
            fill: ${palette.neutral.white};
        }
    `,
    dark: css`
        background: ${palette.neutral['900']};
        color: ${palette.neutral.white};
        & svg {
            fill: ${palette.neutral['900']};
        }
    `
})
