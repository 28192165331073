import {useTranslation} from 'react-i18next'
import {IconContainer} from '@components/ui/icon-container/IconContainer.tsx'
import {SearchLgIcon} from 'src/components/ui/icon'
import {StyledContainer, StyledNotFoundWrapper} from '@pages/not-found/style.ts'
import {routes} from '@utilities/constants'
import {ButtonLink} from '@components/ui/button-link/ButtonLink.tsx'

export const NotFound = () => {
    const {t} = useTranslation()

    return (
        <StyledContainer fullHeight={true}>
            <StyledNotFoundWrapper>
                <IconContainer>
                    <SearchLgIcon />
                </IconContainer>
                <h1>{t('notFound:title')}</h1>
                <p>{t('notFound:subtitle')}</p>
                <ButtonLink variant={'primary'} size={'lg'} to={routes.SSO_LOGIN.path}>
                    {t('notFound:cta')}
                </ButtonLink>
            </StyledNotFoundWrapper>
        </StyledContainer>
    )
}

NotFound.displayName = 'NotFound'
