import {useEffect, useRef} from 'react'
import {Helmet} from 'react-helmet'
import {Trans, useTranslation} from 'react-i18next'
import PageHeader from '@components/commons/page-hero/PageHero.tsx'
import {EmptyResultsBox} from '@/components/commons/empty-results-box/EmptyResultsBox'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {breakpoints} from '@/theme/mediaQueries'
import {useAuthStore} from '@/store/AuthStore'
import {ListingCategoriesCard} from '@/features/categories/listing-categories-card/ListingCategoriesCard'
import {ListingCategoriesLoaderCard} from '@/features/categories/listing-categories-loader-card/ListingCategoriesLoaderCard'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {AlertTriangleIcon, Camera01Icon, SearchLgIcon} from '@/components/ui/icon'
import {generatePath} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useCategories} from '@/features/categories/services/queries/useCategories'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useCategoriesRequestStore} from '@/features/categories/store/CategoriesRequestStore'
import {CategoryRequestOperation} from '@/features/categories/types'
import {CategoriesRequest} from '@/features/categories/categories-request/CategoriesRequest'
import {
    StyledCategoriesContainer,
    StyledCategoriesInputText,
    StyledCategoriesInputTextWrapper,
    StyledCategoriesNote,
    StyledPageHeaderWrapper,
    StyledReadMoreLink,
    StyledListingCategoriesWrapper,
    StyledCategoriesRequestModal,
    StyledCategoriesNoteContainer,
    StyledButtonWrapper
} from './style'
import {PromoBannerWide} from '@/components/commons/promo-banner-wide/PromoBannerWide'
import {useInView} from '@/hooks/useInView'
import Button from '@/components/ui/button/Button'
import Spinner from '@/components/ui/spinner/Spinner'
import {useTheme} from 'styled-components'
import {useListingsCategoriesList} from '@/features/categories/services/queries/useListingsCategoriesList'

export const Categories = () => {
    const {t} = useTranslation()
    const searchRef = useRef<HTMLInputElement | null>(null)
    const user = useAuthStore(state => state.user)
    const showCategoriesModal = useCategoriesRequestStore(state => state.modalVisible)
    const toggleCategoriesModal = useCategoriesRequestStore(state => state.toggleModalVisibility)
    const requestOperation = useCategoriesRequestStore(store => store.requestOperation)
    const {width} = useWindowDimensions()
    const isMobile = width < breakpoints.width['m']
    const loadMoreButtonRef = useRef<HTMLButtonElement>(null)
    const {palette} = useTheme()

    //prevent strange behaviour
    if (!user) {
        throw t('error:default')
    }

    const {
        remappedData: listings,
        isPending: isPendingListings,
        fetchNextPage,
        hasNextPage,
        isFetching,
        onSearch,
        onResetSearch,
        searchValue,
        isError
    } = useListingsCategoriesList({
        urlParams: {
            hostCode: user.code,
            hostPassword: user.password
        }
    })

    const isInView = useInView(loadMoreButtonRef, {threshold: 0.5})

    const {isPending: isPendingCategories} = useCategories()

    useEffect(() => {
        if (isInView && hasNextPage && !isFetching) {
            fetchNextPage()
        }
    }, [isInView, hasNextPage, isFetching])

    if (isError) {
        return <ErrorBox title={t('errors:default')} icon={<AlertTriangleIcon />} />
    }

    return (
        <Flexbox direction="column">
            <StyledCategoriesContainer>
                <Helmet title={t('categories:page_title_meta')} />

                <StyledPageHeaderWrapper justify="space-between" align="end">
                    <PageHeader
                        title={t('categories:title')}
                        subtitle={
                            <Trans
                                i18nKey={'categories:subtitle'}
                                components={[
                                    <StyledReadMoreLink
                                        target="_blank"
                                        href={
                                            'https://www.airbnb.com/help/article/3374?_set_bev_on_new_domain=1704294757_MDBhYzFjYThjNzgz'
                                        }
                                    />
                                ]}
                            />
                        }
                    />

                    {(listings.length > 0 || searchValue) && (
                        <StyledCategoriesInputTextWrapper>
                            <StyledCategoriesInputText
                                isLoading={isFetching}
                                ref={searchRef}
                                type="text"
                                defaultValue={searchValue}
                                typeIcon={<SearchLgIcon size={20} />}
                                inputSize="sm"
                                placeholder={t('commons:search_listing_placeholder')}
                                onChange={event => onSearch(event.target.value)}
                            />
                        </StyledCategoriesInputTextWrapper>
                    )}
                </StyledPageHeaderWrapper>

                {isPendingListings || isPendingCategories ? (
                    <StyledListingCategoriesWrapper>
                        <ListingCategoriesLoaderCard />
                        <ListingCategoriesLoaderCard />
                    </StyledListingCategoriesWrapper>
                ) : (
                    <>
                        {searchValue && listings.length === 0 && (
                            <EmptyResultsBox
                                onResetCb={() => onResetSearch(searchRef)}
                                title={t('commons:no_results_found')}
                                subtitle={t('commons:no_results_listings_message', {value: searchValue})}
                            />
                        )}

                        {!searchValue && listings.length === 0 && (
                            <EmptyResultsBox
                                title={t('commons:empty_listings_results')}
                                subtitle={t('categories:empty_results_subtitle')}
                                customContent={
                                    <ButtonLink
                                        fullWidth={isMobile}
                                        to={`https://www.airbnb.com/hosting/listings`}
                                        target="_blank"
                                    >
                                        {t('categories:listings_cta')}
                                    </ButtonLink>
                                }
                            />
                        )}

                        {listings.length > 0 && (
                            <Flexbox direction="column" gap={8} width="100%">
                                <StyledListingCategoriesWrapper>
                                    {listings.map(listing => (
                                        <ListingCategoriesCard key={listing.id} listing={listing} />
                                    ))}

                                    {hasNextPage && (
                                        <StyledButtonWrapper>
                                            <Button
                                                disabled={isFetching}
                                                ref={loadMoreButtonRef}
                                                onClick={() => fetchNextPage()}
                                                variant="ghost"
                                            >
                                                {t('categories:load_more')}
                                                {isFetching && <Spinner size={16} color={palette.neutral['400']} />}
                                            </Button>
                                        </StyledButtonWrapper>
                                    )}
                                </StyledListingCategoriesWrapper>
                            </Flexbox>
                        )}
                    </>
                )}
            </StyledCategoriesContainer>

            {listings.length > 0 && (
                <>
                    <PromoBannerWide
                        title={t('photoshoot_promo_banner:title')}
                        serviceIcon={<Camera01Icon />}
                        desc={<Trans i18nKey={'photoshoot_promo_banner:subtitle'} components={[<b />, <sup />]} />}
                        cta={
                            <ButtonLink
                                variant="primary"
                                size="lg"
                                fullWidth={isMobile}
                                to={`${generatePath(routes.PHOTOSHOOT_REQUEST.path)}?source=category`}
                            >
                                {t('photoshoot_promo_banner:button')}
                            </ButtonLink>
                        }
                    />

                    <StyledCategoriesNoteContainer>
                        <StyledCategoriesNote gap={1}>
                            <Trans i18nKey={'photoshoot_promo_banner:note'} components={[<sup>1</sup>]} />
                        </StyledCategoriesNote>
                    </StyledCategoriesNoteContainer>
                </>
            )}

            {showCategoriesModal && (
                <StyledCategoriesRequestModal
                    width={requestOperation === CategoryRequestOperation.Enum.add ? 500 : 360}
                    onOverlayClick={toggleCategoriesModal}
                >
                    <CategoriesRequest />
                </StyledCategoriesRequestModal>
            )}
        </Flexbox>
    )
}
