import {forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes, ReactNode, useState} from 'react'
import {Label} from '@components/ui/label/Label.tsx'
import {
    StyledInput,
    StyledInputContainer,
    StyledInputGrid,
    StyledInputWrapper,
    StyledVisibleToggle
} from '@components/commons/input-text/style.ts'
import {EyeIcon, EyeOffIcon} from 'src/components/ui/icon'
import {InputHelpText} from '@components/ui/input-help-text/InputHelpText.tsx'
import {FieldError} from 'react-hook-form'
import InputStatusIcon from '@components/ui/input-status-icon/Input-status-icon.tsx'
import {DefaultNamespace} from 'i18next'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string
    label?: string | DefaultNamespace
    name?: string
    type: HTMLInputTypeAttribute
    typeIcon?: ReactNode
    copyAction?: boolean
    touched?: boolean
    error?: FieldError
    isLoading?: boolean
    /**
     * how to use errorMessage with translations
     *  errorMessage={t(errors.text?.message || '')} this avoid undefined value problems
     */
    errorMessage?: string | DefaultNamespace
    visibilityToggle?: boolean
    helpText?: string | DefaultNamespace
    placeholder?: string
    inputSize?: 'sm' | 'md' | 'lg'
}

export interface CustomInputProps extends Omit<InputProps, 'width'> {
    width?: number
}

const InputText = forwardRef<HTMLInputElement, CustomInputProps>(
    (
        {
            label,
            name,
            type,
            typeIcon,
            /*copyAction,*/
            touched = false,
            isLoading = false,
            errorMessage,
            helpText,
            className,
            disabled,
            placeholder,
            visibilityToggle = false,
            inputSize = 'md',
            width,
            ...rest
        },
        ref
    ) => {
        const [eyeVisible, setEyeVisible] = useState(false)

        return (
            <StyledInputContainer className={className}>
                {label && <Label htmlFor={name}>{label}</Label>}
                <StyledInputGrid>
                    <StyledInputWrapper
                        touched={touched}
                        hasError={!!errorMessage}
                        disabled={disabled}
                        size={inputSize}
                        width={width}
                        visibilityToggle={visibilityToggle}
                    >
                        {typeIcon}
                        <StyledInput
                            ref={ref}
                            name={name}
                            type={eyeVisible ? 'text' : type}
                            placeholder={placeholder}
                            disabled={disabled}
                            {...rest}
                        />
                        {/*input status icon*/}
                        <InputStatusIcon touched={touched} hasError={!!errorMessage} isLoading={isLoading} />
                    </StyledInputWrapper>
                    {/*input Action toggle*/}
                    {visibilityToggle && (
                        <StyledVisibleToggle
                            size={inputSize}
                            disabled={disabled}
                            onClick={() => setEyeVisible(!eyeVisible)}
                        >
                            {eyeVisible ? <EyeIcon size={20} data-color /> : <EyeOffIcon size={20} data-color />}
                        </StyledVisibleToggle>
                    )}
                </StyledInputGrid>
                {/*help text*/}
                <InputHelpText error={errorMessage} helpText={helpText} />
            </StyledInputContainer>
        )
    }
)

export default InputText

InputText.displayName = 'InputText'
