import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Button from '@components/ui/button/Button.tsx'

export const StyledAcceptStepHeader = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        ${typography.textSm};
        font-weight: 600;
        color: ${palette.neutral['900']};
        text-align: center;
        height: 56px;
    `
)

export const StyledAcceptStepHeaderCloseButton = styled(Button)(
    ({theme: {spacing}}) => css`
        position: absolute;
        top: ${spacing * 2}px;
        left: ${spacing * 2}px;
    `
)

export const StyledStepCounter = styled.p(
    ({theme: {typography, palette}}) => css`
        ${typography.textXs};
        font-weight: 400;
        text-align: center;
        color: ${palette.neutral['900']};
    `
)

export const StyledAcceptStepBody = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 6}px;
    `
)

export const StyledAcceptStepDesc = styled(Flexbox)<{disabled?: boolean}>(
    ({disabled, theme: {typography, palette}}) => css`
        opacity: ${disabled ? 0.5 : 1};

        & > h5 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        ,
        & > small {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledAcceptStepFooter = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
    `
)

export const StyledAcceptanceFlowCompleted = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        & > h6 {
            ${typography.textSm};
            color: ${palette.neutral['900']};
            font-weight: 500;
        }

        & > p {
            ${typography.textSm};
            color: ${palette.neutral['600']};
        }
    `
)
