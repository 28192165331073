import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledToggleWrapper = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        & h5 {
            ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
        & small {
            ${typography.textSm};
            color: ${palette.neutral['900']};
            word-break: break-word;
            & > span {
                cursor: pointer;
                margin-left: ${spacing}px;
                text-decoration: underline;
                font-weight: 500;
            }
        }
    `
)
