import {FC, useEffect, useRef} from 'react'
import {StepCard, StepCardContentDirection, StepCardProps} from '@/components/commons/step-card/StepCard'
import {ScrollArea} from '@components/commons/scroll-area/ScrollArea.tsx'
import {StyledScrollbar, StyledSteps, StyledStepsContainer, StyledStepsWrapper} from '@/components/commons/steps/style'

type Steps = Array<StepCardProps & {isCurrent: boolean}>

interface StepsProps {
    steps: Steps
    stepCardContentDirection?: StepCardContentDirection
    className?: string
    hasShadow?: boolean
    hasBorder?: boolean
}

export const Steps: FC<StepsProps> = ({steps, stepCardContentDirection, className, hasShadow, hasBorder}) => {
    const currentStepRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        currentStepRef.current?.scrollIntoView({block: 'nearest', inline: 'start'})
    }, [])

    return (
        <StyledStepsContainer fullHeight={false}>
            <StyledStepsWrapper $hasShadow={!!hasShadow} $hasBorder={!!hasBorder}>
                <ScrollArea scrollbar={<StyledScrollbar orientation="horizontal" />}>
                    <StyledSteps
                        $stepsCount={steps.length}
                        $hasPaddingY={stepCardContentDirection != 'row'}
                        className={className}
                    >
                        {steps.map(step => (
                            <StepCard
                                {...step}
                                stepCardContentDirection={stepCardContentDirection}
                                ref={step.isCurrent ? currentStepRef : null}
                                key={step.number}
                            />
                        ))}
                    </StyledSteps>
                </ScrollArea>
            </StyledStepsWrapper>
        </StyledStepsContainer>
    )
}
