import {StatusDescriptionWrapper} from '@/features/service/components/status-description-wrapper/StatusDescriptionWrapper.tsx'
import {StatusDescriptionIconWrapper} from '@/features/service/components/status-description-icon/StatusDescriptionIconWrapper.tsx'
import {AlertCircleIcon} from '@components/ui/icon'
import {StatusDescriptionTitle} from '@/features/service/components/status-description-title/StatusDescriptionTitle.tsx'
import {useTranslation} from 'react-i18next'
import {StatusDescriptionParagraphsWrapper} from '@/features/service/components/status-description-paragraphs-wrapper/StatusDescriptionParagraphsWrapper.tsx'
import {StatusDescriptionParagraph} from '@/features/service/components/status-description-paragraph/StatusDescriptionParagraph.tsx'
import {Assignment} from '@/features/service/types.ts'
import {FC} from 'react'
import dayjs from '@utilities/dayjs.ts'

export const WalkthroughAssignedUnresponsiveStatusDescription: FC<{
    assignedFreelancer: string | undefined
    assignmentDate: Assignment['to_shoot_at'] | undefined
}> = ({assignedFreelancer, assignmentDate}) => {
    const {t} = useTranslation()
    const assignmentDateDaysLeft = dayjs(assignmentDate).diff(dayjs(), 'days')

    return (
        <StatusDescriptionWrapper>
            <StatusDescriptionIconWrapper>
                <AlertCircleIcon />
            </StatusDescriptionIconWrapper>
            <StatusDescriptionTitle>
                {t('service_details:descriptions:walkthrough:assigned_unresponsive_title')}
            </StatusDescriptionTitle>
            <StatusDescriptionParagraphsWrapper>
                <StatusDescriptionParagraph>
                    {t('service_details:descriptions:walkthrough:assigned_unresponsive_paragraph', {
                        freelancer: assignedFreelancer,
                        daysCount:
                            assignmentDateDaysLeft > 15 ? 15 : assignmentDateDaysLeft < 0 ? 0 : assignmentDateDaysLeft
                    })}
                </StatusDescriptionParagraph>
            </StatusDescriptionParagraphsWrapper>
        </StatusDescriptionWrapper>
    )
}

WalkthroughAssignedUnresponsiveStatusDescription.displayName = 'WalkthroughAssignedUnresponsiveStatusDescription'
