import {FC, ReactNode} from 'react'
import {StyledCloseButton, StyledModal, StyledOverlay} from '@components/ui/modal/style.ts'
import {XCloseIcon} from 'src/components/ui/icon'
import {createPortal} from 'react-dom'
import {useTranslation} from 'react-i18next'

interface ModalProps {
    children: ReactNode
    className?: string
    closeIconOffset?: 'sm' | 'md' | 'lg'
    onClose?: () => void
    onOverlayClick?: () => void
    overlayVariant?: 'filled' | 'gradient'
    width?: number
}

export const Modal: FC<ModalProps> = ({
    children,
    className,
    closeIconOffset = 'md',
    onClose,
    onOverlayClick,
    overlayVariant = 'filled',
    width
}) => {
    const {i18n} = useTranslation()

    return createPortal(
        <>
            <StyledOverlay onClick={onOverlayClick} overlayVariant={overlayVariant} />
            <StyledModal width={width} className={className}>
                {onClose && (
                    <StyledCloseButton
                        dir={i18n.dir()}
                        size="lg"
                        iconOnly
                        variant="ghost"
                        onClick={onClose}
                        $closeIconOffset={closeIconOffset}
                    >
                        <XCloseIcon />
                    </StyledCloseButton>
                )}
                {children}
            </StyledModal>
        </>,
        document.body
    )
}

Modal.displayName = 'Modal'
