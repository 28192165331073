import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'
import InputText from '../input-text/InputText'

export const StyledLangModal = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`

export const StyledLangModalHeader = styled(Flexbox)(
    ({theme: {spacing, palette, typography}}) => css`
        position: sticky;
        top: 0;
        background-color: ${palette.neutral.white};
        width: 100%;
        padding: ${spacing * 4}px;
        border-bottom: 1px solid ${palette.neutral['200']};
        h3 {
            font-size: ${typography.textXl};
            font-weight: 700;
            color: ${palette.neutral['900']};
            flex: 1;
            margin-left: ${spacing * 2}px;
        }
    `
)

export const StyledLangModalHeaderContent = styled(Flexbox)`
    flex: 1;
`

export const StyledInputText = styled(InputText)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.s} {
            width: 100%;
        }
    `
)

export const StyledLangModalList = styled.div(
    ({theme: {typography, spacing, palette, mediaQueries}}) => css`
        height: max-content;
        max-height: 100%;
        padding: ${spacing * 6}px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: ${spacing * 6}px;
        overflow-y: auto;
        button {
            cursor: pointer;
            font-size: ${typography.textSm};
            color: ${palette.neutral['900']};
            padding: ${spacing * 2.5}px ${spacing * 4}px;
            border-radius: 8px;
            transition: ease-in-out 200ms;
            &:not(.current-language):hover {
                background: ${palette.neutral['50']};
            }
            &.current-language {
                border: 1px solid ${palette.neutral.black};
            }
        }
        ${mediaQueries.m} {
            grid-template-columns: repeat(2, 1fr);
        }
        ${mediaQueries.s} {
            grid-template-columns: 1fr;
        }
    `
)
