import axios from '@/axiosInstance.ts'
import {z} from 'zod'
import {ResponseType} from '@/types/commons.ts'
import {CoAlarmListingRequest, CoAlarmStatus} from '@/features/co-alarm/types.ts'
import {CoAlarmSendRequestSchema} from '@/features/co-alarm/components/co-alarm-send-request-form/form/CoAlarmSendRequestFormModel'

/**
 * Get the active listings for CO Alarm Dashboard
 */
// Params
export const HttpGetCoAlarmListingsOptions = z.object({
    params: z.object({
        search: z.string().optional(),
        filter_by: z.literal('co_alarm_status').optional(),
        filter_values: z.array(CoAlarmStatus).optional(),
        cursor: z.string().optional(),
        limit: z.number(),
        response_type: ResponseType,
        order_by: z.enum(['state']).optional()
    })
})
export type HttpGetCoAlarmListingsOptions = z.infer<typeof HttpGetCoAlarmListingsOptions>

// Response
export const HttpGetCoAlarmListingsResponse = z.object({
    listings: CoAlarmListingRequest.array(),
    paging: z.object({
        total_count: z.number(),
        limit: z.number(),
        next_cursor: z.string().nullish()
    })
})
export type HttpGetCoAlarmListingsResponse = z.infer<typeof HttpGetCoAlarmListingsResponse>

// Http request
export const httpGetCoAlarmListings = ({params}: HttpGetCoAlarmListingsOptions) => {
    return axios.get(`/host-services/listings`, {params})
}

/**
 * Get the request details
 */
export const HttpGetCoAlarmRequestOptions = z.object({
    urlParams: z.object({
        listingId: z.coerce.number(),
        requestId: z.coerce.number()
    }),
    params: z.object({
        response_type: ResponseType
    })
})
export type HttpGetCoAlarmRequestOptions = z.infer<typeof HttpGetCoAlarmRequestOptions>

export const httpGetCoAlarmRequest = ({urlParams, params}: HttpGetCoAlarmRequestOptions) => {
    return axios.get(`/host-services/listings/${urlParams.listingId}/co-alarm-requests/${urlParams.requestId}`, {
        params
    })
}

/**
 * Send co alarm request
 */
export const HttpSendCoAlarmRequestOptions = z.object({
    urlParams: z.object({
        listingId: z.string()
    }),
    payload: CoAlarmSendRequestSchema.omit({listing: true}).extend({
        sensors_requested: z.number(),
        country: z.string(),
        formatted_address: z.string(),
        address: z
            .object({
                long_name: z.string(),
                short_name: z.string(),
                types: z.string().array()
            })
            .array()
    })
})
export type HttpSendCoAlarmRequestOptions = z.infer<typeof HttpSendCoAlarmRequestOptions>

export const HttpSendCoAlarmRequestResponse = z.object({
    id: z.number()
})
export type HttpSendCoAlarmRequestResponse = z.infer<typeof HttpSendCoAlarmRequestResponse>

export const httpSendCoAlarmRequest = async ({
    urlParams,
    payload
}: HttpSendCoAlarmRequestOptions): Promise<HttpSendCoAlarmRequestResponse> => {
    const response = await axios.post(`/host-services/listings/${urlParams.listingId}/co-alarm-requests`, payload)
    return response.data
}

/**
 * Update co alarm request
 */
export const HttpUpdateCoAlarmRequestOptions = z.object({
    urlParams: z.object({
        listingId: z.string(),
        requestId: z.string()
    }),
    payload: CoAlarmSendRequestSchema.omit({listing: true, sensors_requested: true}).extend({
        country: z.string(),
        formatted_address: z.string(),
        address: z
            .object({
                long_name: z.string(),
                short_name: z.string(),
                types: z.string().array()
            })
            .array()
    }),
    params: z.object({
        response_type: ResponseType
    })
})

export type HttpUpdateCoAlarmRequestOptions = z.infer<typeof HttpUpdateCoAlarmRequestOptions>

export const httpUpdateCoAlarmRequest = ({urlParams, payload, params}: HttpUpdateCoAlarmRequestOptions) => {
    return axios.patch(
        `/host-services/listings/${urlParams.listingId}/co-alarm-requests/${urlParams.requestId}`,
        payload,
        {
            params
        }
    )
}
