import {Navigate, Outlet, useLocation, useSearchParams} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useEffect, useState} from 'react'
import {LoadingScreen} from '@components/commons/loading-screen/LoadingScreen.tsx'
import {useAuthStore} from '@/store/AuthStore.tsx'
import {mixpanelInit} from '@utilities/mixpanel/mixpanelEventCenter.ts'

const PrivateRoute = () => {
    const user = useAuthStore(state => state.user)
    const accessToken = useAuthStore(state => state.accessToken)
    const setAccessToken = useAuthStore(state => state.setAccessToken)
    const reset = useAuthStore(state => state.reset)
    const [guardCondition, setGuardCondition] = useState(false)
    const {pathname} = useLocation()

    // Intercept the impersonate token from Navigate
    const [searchParams, setSearchParams] = useSearchParams()
    const impersonateAccessToken = searchParams.get('impersonate')

    useEffect(() => {
        if (impersonateAccessToken) {
            reset()
            setAccessToken(impersonateAccessToken)
            searchParams.delete('impersonate')
            setSearchParams(searchParams)
        }
    }, [impersonateAccessToken])

    useEffect(() => {
        if (user) {
            //TODO: write here the necessaries checks
            setGuardCondition(true)
            //Set mixpanel user globally
            mixpanelInit(user.id)
        }
    }, [user])

    return (
        <>
            {!accessToken && <Navigate to={routes.SSO_LOGIN.path} state={{origin: pathname}} />}
            {user && guardCondition ? <Outlet /> : <LoadingScreen />}
        </>
    )
}

export default PrivateRoute
