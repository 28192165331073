import PageHero from '@components/commons/page-hero/PageHero.tsx'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'
import {Fragment, useState} from 'react'
import Container from '@components/ui/container/Container.tsx'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {generatePath, useNavigate} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {AlertTriangleIcon, ChevronLeftIcon} from '@/components/ui/icon'
import {useGetCoAlarmListings} from '@/features/co-alarm/services/useGetCoAlarmListings'
import {ErrorBox} from '@/components/commons/error-box/ErrorBox'
import {CoAlarmRequestFormLoader} from '@/features/co-alarm/components/co-alarm-request-form-loader/CoAlarmRequestFormLoader'
import {CoAlarmSendRequestFormWrapper} from '@/features/co-alarm/components/co-alarm-send-request-form-wrapper/CoAlarmSendRequestFormWrapper'
import {useAuthStore} from '@/store/AuthStore'

export const CoAlarmSendRequest = () => {
    const {t} = useTranslation()
    const user = useAuthStore(state => state.user)
    const [searchListing, setSearchListing] = useState<string | null>(null)
    const navigate = useNavigate()

    // Listings list query
    const {
        remappedData: listings,
        fetchNextPage,
        isPending,
        isLoading,
        isError,
        isFetchingNextPage
    } = useGetCoAlarmListings({
        params: {
            search: searchListing || undefined,
            filter_by: 'co_alarm_status',
            filter_values: ['eligible', 'installed'],
            limit: 10,
            response_type: 'extended',
            order_by: 'state'
        }
    })

    if (user && user?.co_alarm.requests_count > 0) {
        navigate('../co-alarm')
    }

    if (isError) {
        return (
            <Container>
                <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
            </Container>
        )
    }

    return (
        <Fragment>
            <Container>
                <Helmet title={String(t('coAlarm:send_request:page_title_meta'))} />
                <PageHero
                    title={t('coAlarm:send_request:title')}
                    ctaComponent={
                        <ButtonLink variant={'tertiary'} size={'md'} to={generatePath(routes.CO_ALARM_DASHBOARD.path)}>
                            <ChevronLeftIcon size={20} />
                            {t('commons:back')}
                        </ButtonLink>
                    }
                />

                {isLoading && !listings.length && !searchListing ? (
                    <CoAlarmRequestFormLoader />
                ) : (
                    <CoAlarmSendRequestFormWrapper
                        listings={listings}
                        isChangingListingsPage={isFetchingNextPage}
                        onSearchListing={setSearchListing}
                        onChangePage={fetchNextPage}
                        isPendingListings={isPending}
                    />
                )}
            </Container>
        </Fragment>
    )
}
