import axios from '@/axiosInstance.ts'
import {z} from 'zod'
import {DashboardURLParams} from '@route-guards/dashboard-route/DashboardRoute.tsx'
import {HostApprovalRequest} from '@/features/host-approval/types.ts'
import {ResponseType} from '@/types/commons.ts'
import {HostApprovalURLParams} from '@route-guards/host-approval-route/HostApprovalRoute.tsx'

/**
 * httpGetHostApprovalRequests
 * Here we will fetch all the approval requests data for the dashboard cards
 */
export const HttpHostApprovalRequestsResponse = z.array(HostApprovalRequest)
export type HttpHostApprovalRequestsResponse = z.infer<typeof HttpHostApprovalRequestsResponse>

export const httpGetHostApprovalRequests = (urlParams: DashboardURLParams) => {
    return axios.get(`/host-services/hosts/${urlParams.hostCode}/${urlParams.hostPassword}/approval-requests`)
}

/**
 * httpGetHostApprovalRequestDetail
 * Here we will fetch all the approval requests data for the dashboard cards
 */
export const HttpGetHostApprovalRequestDetail = HostApprovalRequest
export type HttpGetHostApprovalRequestDetail = z.infer<typeof HttpGetHostApprovalRequestDetail>

export const HttpGetHostApprovalRequestDetailParams = z.object({
    urlParams: z.object({
        hostCode: z.coerce.number().int(),
        hostPassword: z.string(),
        requestId: z.coerce.number().int()
    }),
    params: z.object({
        response_type: ResponseType
    })
})
export type HttpGetHostApprovalRequestDetailParams = z.infer<typeof HttpGetHostApprovalRequestDetailParams>

//host approval request list
export const httpGetHostApprovalRequestsListParams = z.object({
    urlParams: z.object({
        hostCode: z.coerce.number().int(),
        hostPassword: z.string()
    }),
    params: z.object({
        response_type: ResponseType
    })
})
export type httpGetHostApprovalRequestsListParams = z.infer<typeof httpGetHostApprovalRequestsListParams>

export const httpGetHostApprovalRequestsList = ({urlParams, params}: httpGetHostApprovalRequestsListParams) => {
    return axios.get(`/host-services/${urlParams.hostCode}/${urlParams.hostPassword}/accessibility-tasks`, {
        params
    })
    /* return new Promise<AxiosResponse>(resolve => {
        resolve({
            data: [
                {
                    "id": 3,
                    "entity_id": 97678,
                    "external_id": 1,
                    "host_id": 752074,
                    "external_host_id": 1,
                    "accessibility_project_id": 1,
                    "accessibility_type_id": 1,
                    "status": "pending_host_approval",
                    "approved_at": null,
                    "completed_at": null,
                    "created_at": "2024-07-26T13:13:22.000000Z",
                    "updated_at": "2024-07-26T13:13:22.000000Z",
                    "deleted_at": null,
                    "title": "nj"
                },
                {
                    "id": 5,
                    "entity_id": 97678,
                    "external_id": 1,
                    "host_id": 752074,
                    "external_host_id": 1,
                    "accessibility_project_id": 1,
                    "accessibility_type_id": 1,
                    "status": "pending_host_approval",
                    "approved_at": null,
                    "completed_at": null,
                    "created_at": "2024-07-26T13:13:56.000000Z",
                    "updated_at": "2024-07-26T13:13:56.000000Z",
                    "deleted_at": null,
                    "title": "nj"
                }
            ],
            status: 200,
            statusText: "OK",
            headers: {},
            config: {
                headers: {} as AxiosRequestHeaders // Adding headers to the config object
            }
        });
    });*/
}

export const httpGetHostApprovalRequestDetail = ({urlParams, params}: HttpGetHostApprovalRequestDetailParams) => {
    return axios.get(
        `/host-services/${urlParams.hostCode}/${urlParams.hostPassword}/accessibility-tasks/${urlParams.requestId}`,
        {
            params
        }
    )
    /* return new Promise<AxiosResponse>(resolve => {
        resolve({
            data: {
                "id": 1,
                "entity_id": 824620,
                "external_id": 21,
                "host_id": 1,
                "external_host_id": 195827679,
                "accessibility_project_id": 1,
                "accessibility_type_id": 1,
                "status": "pending_host_approval",
                "approved_at": null,
                "completed_at": null,
                "created_at": "2024-07-30T09:26:12.000000Z",
                "updated_at": "2024-07-30T09:26:12.000000Z",
                "deleted_at": null,
                "title": "Home",
                "entity": {
                    "id": 824620,
                    "code": "3193824620",
                    "title": "Home",
                    "external_id": "000021",
                    "entity_type_id": 1,
                    "region": "Europe",
                    "country": "IT",
                    "city": "Rome",
                    "address": "6 Via Veneto, Rome, RM 00121, Italy",
                    "exclude_for_category": 0,
                    "geo": "Rome",
                    "room_type": "ENTIRE_HOME",
                    "space_type": "Villa",
                    "bedrooms": 5,
                    "experience_category": null,
                    "experience_duration": null,
                    "experience_max_seats": null,
                    "is_quotes_synced": false,
                    "is_tasks_synced": false,
                    "is_payments_synced": false,
                    "created_at": "2023-12-18T15:25:18.000000Z",
                    "updated_at": "2023-12-18T15:25:18.000000Z",
                    "deleted_at": null,
                    "latitude": null,
                    "longitude": null,
                    "currency": null
                },
                "accessibility_project": {
                    "id": 1,
                    "name": "Vacasa",
                    "key_name": "vacasa",
                    "created_at": "2024-07-30T07:28:34.000000Z",
                    "updated_at": "2024-07-30T07:28:34.000000Z"
                },
                "accessibility_type": {
                    "id": 1,
                    "name": "Vacasa",
                    "key_name": "vacasa",
                    "created_at": "2024-07-30T07:28:34.000000Z",
                    "updated_at": "2024-07-30T07:28:34.000000Z"
                },
                "accessibility_images": [
                    {
                        "id": 1,
                        "task_id": 1,
                        "room_id": 1,
                        "amenity_id": 1,
                        "path_location": "public/navigate-accessibility-images/1/fvpVT3ObCHSZ41x65AmlPXrbkjbu3jHp.jpg",
                        "name": "IMG_2958 (1)",
                        "extension": "jpg",
                        "is_host_approved": false,
                        "from_photo_gallery": false,
                        "is_uploaded": false,
                        "uploaded_at": "2024-07-30 09:27:13",
                        "created_at": "2024-07-30T09:27:13.000000Z",
                        "updated_at": "2024-07-30T09:27:13.000000Z",
                        "deleted_at": null,
                        "url": "https://d1vjsbrx8sboy6.cloudfront.net/public/navigate-accessibility-images/1/fvpVT3ObCHSZ41x65AmlPXrbkjbu3jHp.jpg"
                    },
                    {
                        "id": 2,
                        "task_id": 1,
                        "room_id": 5,
                        "amenity_id": 1,
                        "path_location": "public/navigate-accessibility-images/1/KPmFVzYksbnpcvyzV4eMFbYim8sNOcvr.jpg",
                        "name": "IMG_2958 (1)",
                        "extension": "jpg",
                        "is_host_approved": true,
                        "from_photo_gallery": false,
                        "is_uploaded": false,
                        "uploaded_at": "2024-07-30 09:27:15",
                        "created_at": "2024-07-30T09:27:15.000000Z",
                        "updated_at": "2024-07-30T09:27:15.000000Z",
                        "deleted_at": null,
                        "url": "https://d1vjsbrx8sboy6.cloudfront.net/public/navigate-accessibility-images/1/KPmFVzYksbnpcvyzV4eMFbYim8sNOcvr.jpg"
                    }
                ]
            },
            status: 200,
            statusText: "OK",
            headers: {},
            config: {
                headers: {} as AxiosRequestHeaders // Adding headers to the config object
            }
        });
    });*/
}

/**
 * httpSendReviewedApproval
 * Here we will send the host decision for the approval
 */
export const HttpSendReviewedApprovalProps = z.object({
    urlParams: HostApprovalURLParams,
    params: z
        .object({
            id: z.coerce.string(),
            action: z.enum(['accepted', 'removed']),
            old: z.boolean()
        })
        .array()
})

export type HttpSendReviewedApprovalProps = z.infer<typeof HttpSendReviewedApprovalProps>

export const httpSendReviewedApproval = ({urlParams, params}: HttpSendReviewedApprovalProps) => {
    const url = `/host-services/${urlParams.hostCode}/${urlParams.hostPassword}/accessibility-tasks/${urlParams.requestId}/approve`
    return axios.post(url, {images: params})
}
