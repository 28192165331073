import {
    ClearIndicatorProps,
    components,
    DropdownIndicatorProps,
    GroupBase,
    MultiValueRemoveProps,
    OptionProps,
    SingleValueProps
} from 'react-select'
import {CheckIcon, ChevronDownIcon, XCloseIcon} from 'src/components/ui/icon'
import {SelectValue} from '@components/commons/select/Select.tsx'
import {Fragment} from 'react'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {useTheme} from 'styled-components'

const MultiValueRemove = (props: MultiValueRemoveProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
    return (
        <components.MultiValueRemove {...props}>
            <XCloseIcon data-color size={12} />
        </components.MultiValueRemove>
    )
}

const DropdownIndicator = (props: DropdownIndicatorProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDownIcon size={16} />
        </components.DropdownIndicator>
    )
}

const ClearIndicator = (props: ClearIndicatorProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
    return (
        <components.ClearIndicator {...props}>
            <XCloseIcon data-color size={16} />
        </components.ClearIndicator>
    )
}

const SingleValue = (props: SingleValueProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
    if (props.selectProps.menuIsOpen && props.selectProps.isSearchable) return <Fragment></Fragment>

    return <components.SingleValue {...props}>{props.children}</components.SingleValue>
}

const Option = (props: OptionProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
    const {palette} = useTheme()
    return (
        <components.Option {...props}>
            <Flexbox justify="space-between" align="center">
                {props.children}
                {props.isSelected && <CheckIcon color={palette.primary['600']} size={20} />}
            </Flexbox>
        </components.Option>
    )
}

export const customComponents = {
    MultiValueRemove,
    DropdownIndicator,
    ClearIndicator,
    SingleValue,
    Option
}
