import InputSelect from '@/components/commons/select/Select'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledCoAlarmRequestFormWrapper = styled(Flexbox)`
    max-width: 600px;
    width: 100%;
`

export const StyledCoAlarmRequestFormSection = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        & > h4 {
            ${typography.textLg};
            font-weight: 500;
            color: ${palette.neutral[900]};
        }
    `
)

export const StyledListingSelector = styled(InputSelect)`
    width: 100%;

    & .select__control {
        height: 68px;
    }
`

export const StyledCoAlarmRequestSentMessage = styled(Flexbox)(
    ({theme: {palette, typography}}) => css`
        ${typography.textSm};

        & > h4 {
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        & > p {
            color: ${palette.neutral['700']};
        }
    `
)
