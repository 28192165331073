import {palette as mainPalette} from './palette.ts'
import {mediaQueries} from './mediaQueries.ts'
import ResetStyles from './reset.ts'
import {shadows} from './shadows.ts'
import {css, DefaultTheme} from 'styled-components'
import {typography} from './typography.ts'

const GlobalStyles = ResetStyles
export const spacing = 4

interface ThemeInterface {
    name: 'light'
    //TODO: we will support the dark mode decommenting this one and adding the dark types to our style.d.ts
    /*name: 'light' | 'dark'*/
    direction?: 'ltr' | 'rtl'
}

const getAppTheme = ({name = 'light'}: ThemeInterface): DefaultTheme => {
    const palette = mainPalette[name]

    const zIndex = {
        chatbot: 9,
        dashMobileTabBar: 8,
        modalOverlay: 10,
        modal: 11,
        tooltip: 12
    }

    const truncateText = css`
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `

    const link = css`
        ${typography.textSm};
        cursor: pointer;
        text-decoration: none;
        color: ${palette?.primary?.['700']};
        font-weight: 600;
        &:hover {
            color: ${palette?.neutral?.['900']};
            text-decoration: none;
        }
        &:focus {
            color: ${palette?.neutral?.['900']};
        }
        &:visited {
            color: ${palette?.neutral?.['500']};
        }
        &:active {
            color: ${palette?.primary?.['700']};
        }
        &:link {
            color: ${palette?.neutral?.['600']};
        }
    `

    const chatBotTheme = {
        background: palette.neutral.white,
        fontFamily: 'Cereal',
        botBubbleColor: palette.neutral['100'],
        botFontColor: palette.neutral['900'],
        userBubbleColor: palette.primary['500'],
        userFontColor: palette.neutral.white
    }

    return {
        spacing,
        zIndex,
        mediaQueries,
        palette,
        typography,
        shadows,
        truncateText,
        link,
        chatBotTheme
    }
}

export {GlobalStyles, getAppTheme}
