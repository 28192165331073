import {Outlet, useLocation} from 'react-router-dom'
import {StyledMain, StyledPrivateLayout} from './styles'
import React from 'react'
import Header, {MenuItem} from '@components/commons/header/Header.tsx'
import {Footer} from '@components/commons/footer/Footer.tsx'
import {Toaster} from 'react-hot-toast'
import {ScrollToAnchor} from '@utilities/ScrollToAnchorListener.tsx'
import {ChatBotWrapper} from '@/features/chatbot/components/Chatbot.tsx'
import {routes} from '@utilities/constants'

const ITEMS: MenuItem[] = []
type AppLayoutProps = {
    withHeader?: boolean
}
export const AppLayout: React.FC<AppLayoutProps> = ({withHeader}) => {
    const {pathname} = useLocation()
    const showChatbot =
        pathname != routes.SSO_LOGIN.path &&
        pathname != routes.SSO_REDIRECT.path &&
        pathname != routes.CATEGORIES.path &&
        !pathname.includes('approval-request') &&
        !pathname.includes(routes.CO_ALARM_DASHBOARD.path)

    return (
        <>
            <ScrollToAnchor />
            <Toaster position={'top-right'} />
            <StyledPrivateLayout>
                {withHeader && <Header items={ITEMS} />}
                <StyledMain>
                    <Outlet />
                    <Footer />
                </StyledMain>
                {showChatbot && <ChatBotWrapper />}
            </StyledPrivateLayout>
        </>
    )
}

AppLayout.displayName = 'AppLayout'
