import i18next from 'i18next'

export const ALL_COUNTRIES_CODES = [
    'af',
    'al',
    'dz',
    'ad',
    'ao',
    'ag',
    'ar',
    'am',
    'au',
    'at',
    'az',
    'bs',
    'bh',
    'bd',
    'bb',
    'by',
    'be',
    'bz',
    'bj',
    'bt',
    'bo',
    'ba',
    'bw',
    'br',
    'bn',
    'bg',
    'bf',
    'bi',
    'cv',
    'kh',
    'cm',
    'ca',
    'cf',
    'td',
    'cl',
    'cn',
    'co',
    'km',
    'cg',
    'cd',
    'cr',
    'ci',
    'hr',
    'cy',
    'cz',
    'dk',
    'dj',
    'dm',
    'do',
    'ec',
    'eg',
    'sv',
    'gq',
    'er',
    'ee',
    'sz',
    'et',
    'fj',
    'fi',
    'fr',
    'ga',
    'gm',
    'ge',
    'de',
    'gh',
    'gr',
    'gd',
    'gt',
    'gn',
    'gw',
    'gy',
    'ht',
    'hn',
    'hu',
    'is',
    'in',
    'id',
    'ir',
    'ie',
    'il',
    'it',
    'jm',
    'jp',
    'jo',
    'kz',
    'ke',
    'ki',
    'kr',
    'kw',
    'kg',
    'la',
    'lv',
    'lb',
    'ls',
    'lr',
    'ly',
    'li',
    'lt',
    'lu',
    'mg',
    'mw',
    'my',
    'mv',
    'ml',
    'mt',
    'mh',
    'mr',
    'mu',
    'mx',
    'fm',
    'md',
    'mc',
    'mn',
    'me',
    'ma',
    'mz',
    'mm',
    'na',
    'nr',
    'np',
    'nl',
    'nz',
    'ni',
    'ne',
    'ng',
    'mk',
    'no',
    'om',
    'pk',
    'pw',
    'pa',
    'pg',
    'py',
    'pe',
    'ph',
    'pl',
    'pt',
    'qa',
    'ro',
    'rw',
    'kn',
    'lc',
    'vc',
    'ws',
    'sm',
    'st',
    'sa',
    'sn',
    'rs',
    'sc',
    'sl',
    'sg',
    'sk',
    'si',
    'sb',
    'so',
    'za',
    'ss',
    'es',
    'lk',
    'sd',
    'sr',
    'se',
    'ch',
    'tj',
    'tz',
    'th',
    'tl',
    'tg',
    'to',
    'tt',
    'tn',
    'tr',
    'tm',
    'tv',
    'ug',
    'ae',
    'gb',
    'us',
    'uy',
    'uz',
    'vu',
    've',
    'vn',
    'ye',
    'zm',
    'zw'
]

export const EXCLUDED_COUNTRIES = ['ua', 'ir', 'sy', 'kp', 'cu', 'mm', 'ru', 'by', 'cn', 'jp', 'ar']
export const REDUCED_FORM_COUNTRIES = ['mx', 'br', 'us']

export const generateLocalizedCountriesOptions = () => {
    const COUNTRIES_CODES = ALL_COUNTRIES_CODES.filter(code => !EXCLUDED_COUNTRIES.includes(code))

    return COUNTRIES_CODES.map(code => ({
        code: code,
        value: i18next.t(`coAlarm:countries:${code}`),
        label: (
            <span style={{display: 'flex', alignItems: 'center', gap: 4}} key={code}>
                <img src={`https://flagcdn.com/w20/${code}.png`} width="20" height="14" alt={code} />
                <p>{i18next.t(`coAlarm:countries:${code}`)}</p>
            </span>
        )
    }))
}
