import {CSSProperties, forwardRef, PropsWithChildren, ReactNode, useState} from 'react'
import * as RTooltip from '@radix-ui/react-tooltip'
import {StyledTooltipContent} from '@components/ui/tooltip/style.ts'

interface TooltipProps extends PropsWithChildren {
    content: ReactNode
    contentProps?: RTooltip.TooltipContentProps
    delayDuration?: number
    maxWidth?: CSSProperties['maxWidth']
    portalProps?: RTooltip.TooltipPortalProps
    rootProps?: RTooltip.TooltipProps
    side?: 'top' | 'right' | 'bottom' | 'left'
    trigger: ReactNode
    triggerProps?: RTooltip.TooltipTriggerProps
    variant?: 'light' | 'dark'
}

/**
 * sample usage
 *
<Tooltip
    trigger={
        <Button variant="ghost" iconOnly onClick={() => console.log('clone')}>
            <Copy03Icon fill={'transparent'} />
        </Button>
    }
    content={t('projects:table:duplicate_cta_tooltip')}
/>
*
*/
export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
    (
        {
            content,
            contentProps,
            delayDuration = 350,
            maxWidth,
            portalProps,
            rootProps,
            side,
            trigger,
            triggerProps,
            variant = 'dark'
        },
        ref
    ) => {
        //This logic allows to open the tooltip even on clicking the trigger
        const [isOpenByClick, setIsOpenByClick] = useState(false)

        return (
            <RTooltip.Provider delayDuration={delayDuration}>
                <RTooltip.Root
                    {...rootProps}
                    open={rootProps?.open || isOpenByClick || undefined}
                    onOpenChange={open => {
                        setIsOpenByClick(false)
                        rootProps?.onOpenChange?.(open)
                    }}
                >
                    <RTooltip.Trigger asChild onClick={() => setIsOpenByClick(true)} {...triggerProps}>
                        {trigger}
                    </RTooltip.Trigger>
                    <RTooltip.Portal {...portalProps}>
                        <StyledTooltipContent
                            {...contentProps}
                            variant={variant}
                            side={side}
                            ref={ref}
                            $maxWidth={maxWidth}
                        >
                            {content}
                            <RTooltip.Arrow width={16} height={6} />
                        </StyledTooltipContent>
                    </RTooltip.Portal>
                </RTooltip.Root>
            </RTooltip.Provider>
        )
    }
)

Tooltip.displayName = 'Tooltip'
