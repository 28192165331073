import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'
import {Modal} from '@components/ui/modal/Modal.tsx'
import Button from '@/components/ui/button/Button'

export const StyledMatterportModal = styled(Modal)(
    ({theme: {mediaQueries}}) => css`
        display: grid;
        grid-template-rows: auto minmax(0, 1fr) auto;
        ${mediaQueries.m} {
            top: unset;
            bottom: 0;
            transform: translate(-50%, 0);
            max-height: 100%;
            max-width: 100%;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    `
)
export const StyledMatterportTitle = styled.h2(
    ({theme: {typography, palette, spacing}}) => css`
        min-height: 56px;
        padding: ${spacing * 5}px ${spacing * 14}px;
        border-bottom: 1px solid ${palette.neutral['200']};
        font-size: ${typography.textSm};
        font-weight: 700;
        text-align: center;
        color: ${palette.neutral['900']};
    `
)

//Intro
export const StyledMatterportIntroCopy = styled(Flexbox)(
    ({theme: {typography, palette, spacing}}) => css`
        padding: ${spacing * 6}px;
        color: ${palette.neutral['900']};
        h3 {
            font-size: ${typography.textXl};
            font-weight: 500;
        }
        p,
        ul {
            font-size: ${typography.textSm};
        }
        ul {
            list-style-type: disc;
            padding-left: ${spacing * 5}px;
        }
        a {
            text-decoration: underline;
            text-underline-offset: ${spacing / 2}px;
            cursor: pointer;
        }
    `
)

export const StyledMatterportIntroFooter = styled(Flexbox)(
    ({theme: {spacing, palette}}) => css`
        padding: ${spacing * 4}px;
        border-top: 1px solid ${palette.neutral['200']};
    `
)

//Review
export const StyledMatterportSectionLoaderGrid = styled.div(
    ({theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        width: 100%;
        gap: ${spacing * 8}px;
        margin: ${spacing * 4}px 0 ${spacing * 8}px 0;
        ${mediaQueries.l} {
            grid-template-columns: 1fr 1fr;
        }
        ${mediaQueries.m} {
            grid-template-columns: 1fr;
            grid-row-gap: ${spacing * 8}px;
        }
    `
)

export const StyledMatterportReviewContent = styled(Flexbox)(
    ({theme: {palette, spacing, typography}}) => css`
        padding: ${spacing * 6}px;
        color: ${palette.neutral['900']};
        h2 {
            font-size: ${typography.textXl};
        }
    `
)

export const StyledMatterportRoomSkeleton = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        margin-top: ${spacing * 10}px;
    `
)

export const StyledMatterportRoom = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        h3 {
            font-size: ${typography.textLg};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledMatterportAmenity = styled(Flexbox)(
    ({theme: {typography, palette}}) => css`
        h4 {
            font-size: ${typography.textMd};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        p {
            font-size: ${typography.textSm};
        }
    `
)

export const StyledMatterportAccessibilityGrid = styled.div<{$imagesGridStart?: 4 | 3}>(
    ({$imagesGridStart, theme: {spacing, mediaQueries}}) => css`
        display: grid;
        grid-template-columns: repeat(${$imagesGridStart}, minmax(0, 1fr));
        width: 100%;
        margin-top: ${spacing * 6}px;
        gap: ${spacing * 8}px;
        ${mediaQueries.l} {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        ${mediaQueries.m} {
            grid-template-columns: minmax(0, 1fr);
        }
    `
)

export const StyledMatterportAccessibilityCard = styled(Flexbox)(
    ({theme: {palette}}) => css`
        img {
            border-radius: 12px;
            background-color: ${palette.neutral[200]};
            width: 100%;
            aspect-ratio: 1.5/1;
            object-fit: contain;
        }
    `
)

export const StyledMatterportAccessibilityCardButtonsWrapper = styled(Flexbox)`
    flex-wrap: wrap;
`

export const StyledMatterportAccessibilityCardButton = styled(Button)`
    flex: 1;
    white-space: nowrap;
`

export const StyledMatterportReviewFooter = styled(Flexbox)<{$isFixed: boolean}>(
    ({$isFixed, theme: {spacing, palette, mediaQueries, typography}}) => css`
        align-items: center;
        padding: ${spacing * 4}px ${spacing * 6}px;
        border-top: 1px solid ${palette.neutral['300']};
        background: ${palette.neutral.white};
        position: ${$isFixed ? 'fixed' : 'relative'};
        bottom: 0;
        left: 0;
        & p {
            ${typography.textMd};
            font-weight: 500;
        }

        ${mediaQueries.m} {
            flex-direction: column;
            align-items: flex-start;
            gap: ${spacing * 4}px;
        }
    `
)

export const StyledMatterportReviewFooterButtons = styled(Flexbox)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            width: 100%;
            justify-content: space-between;
        }
    `
)
