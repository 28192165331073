import Container from '@components/ui/container/Container.tsx'
import {Helmet} from 'react-helmet'
import {Trans, useTranslation} from 'react-i18next'
import {useTheme} from 'styled-components'
import {AirbnbSSOWidget} from '@/features/sso/AirbnbSSOWidget.tsx'
import {AirbnbIcon, CategoryIsland} from 'src/components/ui/icon'
import CoAlarmLogoIcon from '@assets/images/co-alarm/logo_icon.svg'
import {useLocation} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {ReactElement, ReactNode} from 'react'
import {StyledLearnMoreLink} from './style'

type SSOLoginUI = {
    icon: ReactElement
    title: string
    subtitle: string
    disclaimer: ReactNode
    origin?: string
}

export const SsoLogin = () => {
    const {palette} = useTheme()
    const {t} = useTranslation()
    const {state} = useLocation()

    let ssoLoginUI: SSOLoginUI
    switch (state?.origin) {
        case routes.CATEGORIES.path: {
            ssoLoginUI = {
                icon: <CategoryIsland size={64} fill={palette.neutral.black} />,
                title: 'categories:sso:title',
                subtitle: 'categories:sso:subtitle',
                disclaimer: <Trans i18nKey="categories:sso:disclaimer" />,
                origin: routes.CATEGORIES.path
            }
            break
        }
        case routes.CO_ALARM_DASHBOARD.path: {
            ssoLoginUI = {
                icon: <img src={CoAlarmLogoIcon} alt={'smoke-icon'} />,
                title: 'coAlarm:login:title',
                subtitle: 'coAlarm:login:subtitle',
                disclaimer: (
                    <StyledLearnMoreLink
                        variant="tertiary"
                        to="https://www.airbnb.com/help/article/3267"
                        target="_blank"
                    >
                        {t('commons:learn_more')}
                    </StyledLearnMoreLink>
                ),
                origin: routes.CO_ALARM_DASHBOARD.path
            }
            break
        }
        default: {
            ssoLoginUI = {
                icon: <AirbnbIcon size={64} fill={palette.primary['600']} />,
                title: 'sso_login:title',
                subtitle: 'sso_login:subtitle',
                disclaimer: <Trans i18nKey="sso_login:disclaimer" />
            }
        }
    }

    return (
        <Container>
            <Helmet title={String(t('sso_login:page_title_meta'))} />

            <AirbnbSSOWidget
                serviceIcon={ssoLoginUI.icon}
                title={t(ssoLoginUI.title)}
                subtitle={t(ssoLoginUI.subtitle)}
                disclaimer={ssoLoginUI.disclaimer}
                origin={ssoLoginUI?.origin}
            />
        </Container>
    )
}

SsoLogin.displayName = 'SsoLogin'
