import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import Container from '@components/ui/container/Container.tsx'
import {useServiceDetails} from '@/features/service/services/queries/useServiceDetails.ts'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'
import {useOutletContext} from 'react-router-dom'
import {AlertTriangleIcon} from '@components/ui/icon'
import {ErrorBox} from '@components/commons/error-box/ErrorBox.tsx'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {getSteps} from '@/features/service/utils.tsx'
import {Helmet} from 'react-helmet'
import {
    StyledServiceDetails,
    StyledCancelServiceSection,
    StyledServiceDetailsSteps
} from '@pages/service-details/style.ts'
import {ServiceDetailsHero} from '@/features/service/components/service-details-hero/ServiceDetailsHero.tsx'
import {getServiceType} from '@utilities/helpers.ts'
import Button from '@components/ui/button/Button.tsx'
import {useHostParamsStore} from '@/store/HostParamsStore.ts'
import {ServiceType} from '@/types/commons.ts'
import {useServiceCancellationStore} from '@/features/service-cancel/store/serviceCancellationStore.tsx'
import {ServiceCancellationProcess} from '@/features/service-cancel/components/service-cancellation-process/ServiceCancellationProcess.tsx'
import {Actions} from '@/features/service-actions/components/actions/Actions.tsx'
import {ServiceStatusWrapper} from '@/features/service/components/service-status-wrapper/ServiceStatusWrapper.tsx'
import {FooterNotes} from '@/features/service/components/footer-notes/FooterNotes.tsx'
import {ServiceResources} from '@/features/service-resources/components/ServiceResources.tsx'
import {isAxiosError} from 'axios'

const serviceTypeToCancelServiceCtaKey = {
    experience_video: 'service_details:cancel_service_experience_video',
    home: 'service_details:cancel_service_home',
    matterport: 'service_details:cancel_service_matterport',
    walkthrough: 'service_details:cancel_service_walkthrough'
} satisfies Record<ServiceType, string>

export const ServiceDetails = () => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const setHostParams = useHostParamsStore(state => state.setHostParams)
    const isCancelServiceModalVisible = useServiceCancellationStore(state => state.modalVisible)
    const toggleCancelServiceModal = useServiceCancellationStore(state => state.cancellationServiceModalToggle)
    const {
        data: serviceDetails,
        isLoading,
        isError
    } = useServiceDetails(
        {
            urlParams,
            params: {response_type: 'extended'}
        },
        {
            retry: false,
            throwOnError: error => {
                //it doesn't throw the error if it's 404 because it should show the invalid link error
                return !(isAxiosError(error) && error.response?.status == 404)
            }
        }
    )

    useEffect(() => {
        setHostParams({hostCode: urlParams.hostCode, hostPassword: urlParams.hostPassword})
    }, [urlParams])

    if (isLoading) {
        return (
            <Container>
                <Flexbox width="100%" height="100%" justify="center" align="center">
                    <Spinner size={40} />
                </Flexbox>
            </Container>
        )
    }
    if (
        isError ||
        !serviceDetails ||
        (serviceDetails.quote.quote_status != 'signed' &&
            serviceDetails.quote.quote_status != 'completed' &&
            serviceDetails.quote.quote_status != 'cancelled')
    ) {
        return (
            <Container>
                <ErrorBox title={t('errors:invalid_url')} icon={<AlertTriangleIcon />} />
            </Container>
        )
    }

    const serviceType = getServiceType(serviceDetails.task_media.name, serviceDetails.task_category.name)
    //TODO: use the following only when we have to alert user for some delay
    //const taskStatus = serviceQuery.data.assignment?.task_status.status
    /*const isPostProdStatus =
        serviceType == 'home' &&
        (taskStatus == 'pending_assets' || taskStatus == 'needs_more_assets' || taskStatus == 'assets_in_review')*/

    return (
        <StyledServiceDetails>
            <Helmet title={t('service_details:page_title_meta')} />
            {/*{isPostProdStatus && (
                <SubHeaderBanner
                    type={'danger'}
                    title={t('service_details:service_delay_banner_title')}
                    subtitle={t('service_details:service_delay_banner_subtitle')}
                />
            )}*/}
            <ServiceDetailsHero currentService={serviceDetails} />
            <div className="quote-details-content">
                {serviceDetails.quote.quote_status != 'cancelled' && (
                    <StyledServiceDetailsSteps
                        hasShadow
                        steps={getSteps({
                            assignedFreelancer: serviceDetails.assignment?.photographer?.first_name,
                            assignmentDate: serviceDetails.assignment?.to_shoot_at,
                            assignmentStatus: serviceDetails.assignment?.status,
                            postProdStatus: serviceDetails.assignment?.task_status.post_prod_status,
                            quoteAcceptedAt: serviceDetails.quote.accepted_at,
                            quoteStatus: serviceDetails.quote.quote_status,
                            serviceType: serviceType,
                            taskStatus: serviceDetails.assignment?.task_status.status
                        })}
                    />
                )}

                <ServiceStatusWrapper />

                <Container>
                    {serviceDetails.actions.length >= 1 && <Actions service={serviceDetails} />}

                    {serviceDetails.tips && serviceDetails.tips.length > 0 && (
                        <ServiceResources resources={serviceDetails.tips} />
                    )}

                    {serviceDetails.quote.can_cancel && (
                        <StyledCancelServiceSection>
                            <Button
                                size="sm"
                                variant="tertiary"
                                onClick={() => toggleCancelServiceModal(serviceDetails)}
                            >
                                {t(serviceTypeToCancelServiceCtaKey[serviceType])}
                            </Button>
                            {isCancelServiceModalVisible && <ServiceCancellationProcess />}
                        </StyledCancelServiceSection>
                    )}
                    {serviceDetails.assignment?.task_status.status == 'accepted' &&
                        serviceDetails.assignment?.task_status.coordination_status == 'host_not_ready' && (
                            <FooterNotes />
                        )}
                </Container>
            </div>
        </StyledServiceDetails>
    )
}

ServiceDetails.displayName = 'ServiceDetails'
