import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledHostApprovalHeader = styled(Flexbox)`
    ${({theme: {typography, palette, spacing}}) => css`
        position: relative;
        margin-bottom: ${spacing * 4}px;
        & > span {
            position: absolute;
            left: -16px;
            width: 10px;
            height: 10px;
            border-radius: 8px;
            background: ${palette.primary['500']};
        }
        & > h4 {
            ${typography.textSm};
            color: ${palette.neutral['900']};
            font-weight: 500;
        }
    `}
`

export const StyledHostApprovalCard = styled(Flexbox)`
    ${({theme: {spacing, palette}}) => css`
        min-width: 320px;
        border: 1px solid ${palette.neutral['200']};
        border-radius: 12px;
        padding: ${spacing * 4}px ${spacing * 4}px ${spacing * 2.5}px;
    `}
`

export const StyledHostApprovalCardInfo = styled.div`
    ${({theme: {typography, palette}}) => css`
        & > h4 {
            ${typography.textSm};
            color: ${palette.neutral['900']};
            font-weight: 500;
        }
        & > p {
            ${typography.textSm};
        }
    `}
`
