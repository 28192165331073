import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'

export const StyledNotificationConsentBody = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        margin-top: ${spacing * 10}px;
        padding: ${spacing * 6}px;
    `
)

export const StyledNotificationConsentDesc = styled(Flexbox)<{disabled?: boolean}>(
    ({disabled, theme: {typography, palette}}) => css`
        opacity: ${disabled ? 0.5 : 1};

        & > h5 {
            ${typography.textXl};
            font-weight: 500;
            color: ${palette.neutral['900']};
        }

        ,
        & > small {
            ${typography.textSm};
            color: ${palette.neutral['900']};
        }
    `
)

export const StyledNotificationConsentFooter = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        padding: ${spacing * 4}px ${spacing * 6}px;
    `
)
