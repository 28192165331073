import {ComponentProps, CSSProperties, ForwardedRef, forwardRef, ReactNode} from 'react'
import {StyledFlexbox} from '@components/ui/flexbox/style.ts'

type HTMLBlockElement = 'div' | 'span' | 'form' | 'article' | 'main' | 'header' | 'footer' | 'section'

export type FlexBoxCustomProps = {
    className?: string
    children: ReactNode
    justify?: CSSProperties['justifyContent']
    align?: CSSProperties['alignItems']
    direction?: CSSProperties['flexDirection']
    gap?: number
    width?: CSSProperties['width']
    height?: CSSProperties['height']
    padding?: number
}

type OmittedComponentProps<T extends HTMLBlockElement = 'div'> = Omit<
    ComponentProps<T>,
    keyof FlexBoxCustomProps | 'ref' | 'as'
>

type FlexboxProps<T extends HTMLBlockElement = 'div'> = Omit<
    ComponentProps<T>,
    keyof FlexBoxCustomProps | 'ref' | 'as'
> & {
    as?: T
} & FlexBoxCustomProps

export const Flexbox = forwardRef(
    <T extends HTMLBlockElement = 'div'>(
        {children, className, justify, align, direction, gap, width, height, padding, as, ...rest}: FlexboxProps<T>,
        ref: ForwardedRef<HTMLDivElement>
    ) => {
        return (
            <StyledFlexbox
                {...(rest as OmittedComponentProps)}
                as={as ?? 'div'}
                className={className}
                justify={justify}
                align={align}
                direction={direction}
                gap={gap}
                width={width}
                height={height}
                padding={padding}
                ref={ref}
            >
                {children}
            </StyledFlexbox>
        )
    }
)

Flexbox.displayName = 'Flexbox'
