import {StyledMatterportIntroFooter} from './style'
import Button from '@/components/ui/button/Button'
import {useTranslation} from 'react-i18next'

export const MatterportIntroFooter = ({onClose}: {onClose: () => void}) => {
    const {t} = useTranslation()

    return (
        <StyledMatterportIntroFooter align="center" justify="space-between" width={'100%'}>
            <Button variant="tertiary" onClick={onClose}>
                {t('matterport:accessibility_features:intro:close')}
            </Button>
            <Button variant="primary" onClick={onClose}>
                {t('matterport:accessibility_features:intro:continue')}
            </Button>
        </StyledMatterportIntroFooter>
    )
}
