import {z} from 'zod'

export const MANUAL_ADDRESS_FORM_MODEL = {
    firstAddressLine: {
        name: 'address_line_1',
        label: 'coAlarm:smokeForm:manual_address_modal:address_line_1',
        defaultValue: '',
        placeholder: ''
    },
    streetNumber: {
        name: 'street_number',
        label: 'coAlarm:smokeForm:manual_address_modal:street_number',
        defaultValue: '',
        placeholder: ''
    },
    secondAddressLine: {
        name: 'address_line_2',
        label: 'coAlarm:smokeForm:manual_address_modal:address_line_2',
        defaultValue: '',
        placeholder: ''
    },
    thirdAddressLine: {
        name: 'address_line_3',
        label: 'coAlarm:smokeForm:manual_address_modal:address_line_3',
        defaultValue: '',
        placeholder: ''
    },
    fourthAddressLine: {
        name: 'address_line_4',
        label: 'coAlarm:smokeForm:manual_address_modal:address_line_4',
        defaultValue: '',
        placeholder: ''
    },
    city: {
        name: 'city',
        label: 'coAlarm:smokeForm:manual_address_modal:city',
        defaultValue: '',
        placeholder: ''
    },
    state: {
        name: 'state',
        label: 'coAlarm:smokeForm:manual_address_modal:state',
        defaultValue: '',
        placeholder: ''
    },
    postalCode: {
        name: 'postal_code',
        label: 'coAlarm:smokeForm:manual_address_modal:postal_code',
        defaultValue: '',
        placeholder: ''
    }
} as const

export const ManualAddressFormSchema = z.object({
    address_line_1: z.string().min(3, {message: 'coAlarm:smokeForm:manual_address_modal:address_line_1_error'}),
    street_number: z.string().min(1, {message: 'coAlarm:smokeForm:manual_address_modal:street_number_error'}),
    address_line_2: z.string().nullish(),
    address_line_3: z.string().optional(),
    address_line_4: z.string().optional(),
    city: z.string().min(1, {message: 'coAlarm:smokeForm:manual_address_modal:city_error'}),
    state: z.string().min(1, {message: 'coAlarm:smokeForm:manual_address_modal:state_error'}),
    postal_code: z.string().min(1, {message: 'coAlarm:smokeForm:manual_address_modal:postal_code_error'})
})

export type ManualAddressFormSchema = z.infer<typeof ManualAddressFormSchema>
