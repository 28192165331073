import {useTranslation} from 'react-i18next'
import {
    StyledDeclineStepBody,
    StyledDeclineStepDesc,
    StyledDeclineStepFinalForm,
    StyledDeclineStepFooter,
    StyledDeclineStepWrapper
} from '@/features/service-decline/components/service-decline-process/style.ts'
import Button from '@components/ui/button/Button.tsx'
import {useDeclineServiceStore} from '@/features/service-decline/store/declineServiceStore.tsx'
import Divider from '@components/ui/divider/Divider.tsx'
import {useServiceDecline} from '@/features/service-decline/services/queries/useServiceDecline.ts'
import {useDeclineServiceReasons} from '@/features/service-decline/services/queries/useDeclineServiceReasons.ts'
import {Radio} from '@components/commons/radio/Radio.tsx'
import TextArea from '@components/commons/textarea/TextArea.tsx'
import {SubmitHandler, useForm} from 'react-hook-form'
import {DeclineStepsConfigByService, ReasonsFormLoadingSkeleton} from '@/features/service-decline/utils.tsx'
import {ServiceDeclineSchema} from '@/features/service-decline/types.ts'
import {zodResolver} from '@hookform/resolvers/zod'
import Spinner from '@components/ui/spinner/Spinner.tsx'
import {useMemo} from 'react'
import {errorHandler, getServiceType} from '@utilities/helpers.ts'
import {useHostParamsStore} from '@/store/HostParamsStore.ts'
import {DECLINE_OTHER_REASON_ID} from '@utilities/constants'
import toast from 'react-hot-toast'

export const ServiceDeclineFinalStep = () => {
    const {t} = useTranslation()
    //host params store
    const hostCode = useHostParamsStore(state => state.hostCode)
    const hostPassword = useHostParamsStore(state => state.hostPassword)
    //decline service store
    const serviceToDecline = useDeclineServiceStore(state => state.service)
    const declineServiceModalToggle = useDeclineServiceStore(state => state.declineServiceModalToggle)
    const service = useDeclineServiceStore(state => state.service)
    const serviceType = useMemo(
        () => (service ? getServiceType(service.task_media.name, service.task_category.name) : 'home'),
        [service]
    )
    //get decline service quote reasons
    const {data: declineReasons, isPending: pendingReasons} = useDeclineServiceReasons({
        task_media_name: service && service.task_media.name != 'video' ? service.task_media.name : 'photo',
        by: 'host'
    })
    //decline service quote mutation
    const {mutate: declineQuoteMutation} = useServiceDecline({
        onSuccess: () => {
            declineServiceModalToggle()
            toast.success(t('service_decline:decline_success'), {duration: 4500})
        },
        onError: error => errorHandler(error)
    })
    //reason form model and submit handler
    const {
        formState: {isSubmitting, isValid},
        watch,
        register,
        handleSubmit
    } = useForm<ServiceDeclineSchema>({
        resolver: zodResolver(ServiceDeclineSchema),
        defaultValues: {
            notes: ''
        }
    })

    const onSubmit: SubmitHandler<ServiceDeclineSchema> = data => {
        if (serviceToDecline?.id && hostCode && hostPassword) {
            declineQuoteMutation({
                hostCode: hostCode,
                hostPassword: hostPassword,
                notes: data.notes,
                reason_id: data.reason_id,
                serviceID: serviceToDecline.id
            })
        }
    }

    return (
        <StyledDeclineStepWrapper>
            <Divider topSpacing={0} bottomSpacing={0} />

            <StyledDeclineStepBody direction={'column'} gap={6}>
                <StyledDeclineStepDesc direction={'column'} gap={2}>
                    <h5>{t(DeclineStepsConfigByService['final'][serviceType].title)}</h5>
                    <small>{t(DeclineStepsConfigByService['final'][serviceType].subtitle)}</small>
                </StyledDeclineStepDesc>
                <StyledDeclineStepFinalForm onSubmit={handleSubmit(onSubmit)}>
                    {pendingReasons && <ReasonsFormLoadingSkeleton />}
                    {!pendingReasons &&
                        declineReasons &&
                        declineReasons.map(reason => (
                            <Radio
                                key={reason.id}
                                value={reason.id}
                                label={t(
                                    `service_decline:reasons:${service?.task_media.name}:${reason.key_name.replace(
                                        'reasons.',
                                        ''
                                    )}`
                                )}
                                labelPosition={'right'}
                                {...register('reason_id')}
                            />
                        ))}
                    {watch('reason_id') == DECLINE_OTHER_REASON_ID && (
                        <TextArea {...register('notes')} label={t('commons:reason')} />
                    )}
                </StyledDeclineStepFinalForm>
            </StyledDeclineStepBody>

            <Divider topSpacing={0} bottomSpacing={0} />
            <StyledDeclineStepFooter justify={'space-between'}>
                <Button
                    variant={'tertiary'}
                    size={'md'}
                    onClick={() => declineServiceModalToggle()}
                    disabled={isSubmitting}
                >
                    {t(DeclineStepsConfigByService['final'][serviceType].secondary_cta)}
                </Button>
                <Button
                    variant={'primary'}
                    size={'md'}
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting || !isValid || pendingReasons}
                >
                    {isSubmitting && <Spinner size={20} />}
                    {t(DeclineStepsConfigByService['final'][serviceType].primary_cta)}
                </Button>
            </StyledDeclineStepFooter>
        </StyledDeclineStepWrapper>
    )
}

ServiceDeclineFinalStep.displayName = 'ServiceDeclineFinalStep'
