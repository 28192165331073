import {Helmet} from 'react-helmet'
import {useTranslation} from 'react-i18next'
import PageHero from '@/components/commons/page-hero/PageHero'
import {CoAlarmDashboardListings} from '@/features/co-alarm/components/co-alarm-dashboard-listings/CoAlarmDashboardListings'
import {StyledCoAlarmDashboardContainer} from '@/pages/co-alarm/dashboard/style'
import {useAuthStore} from '@/store/AuthStore'
import {ButtonLink} from '@/components/ui/button-link/ButtonLink'
import {generatePath} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import {breakpoints} from '@/theme/mediaQueries'

export const CoAlarmDashboard = () => {
    const {t} = useTranslation()
    const user = useAuthStore(state => state.user)
    const {width} = useWindowDimensions()
    const isMobile = width < breakpoints.width.l

    return (
        <StyledCoAlarmDashboardContainer>
            <Helmet title={String(t('coAlarm:page_title_meta'))} />
            <PageHero
                title={t('coAlarm:dashboard:title')}
                subtitle={t('coAlarm:dashboard:subtitle')}
                endSlot={
                    isMobile &&
                    (user?.co_alarm.status == 'eligible' || user?.co_alarm.status == 'installed') &&
                    user?.co_alarm.requests_count == 0 && (
                        <ButtonLink to={generatePath(routes.CO_ALARM_SEND_REQUEST.path)}>
                            {t('coAlarm:dashboard:cta')}
                        </ButtonLink>
                    )
                }
            />
            <CoAlarmDashboardListings />
        </StyledCoAlarmDashboardContainer>
    )
}
