import {z} from 'zod'

export const CO_ALARM_SEND_REQUEST_FORM_MODEL = {
    listing: {
        name: 'listing',
        label: 'coAlarm:form:listing',
        defaultValue: '',
        placeholder: 'commons:search_listing_placeholder',
        helpText: ''
    },
    sensorsRequested: {
        name: 'sensors_requested',
        label: '',
        defaultValue: 1,
        placeholder: '',
        helpText: ''
    },
    firstName: {
        name: 'first_name',
        label: 'coAlarm:form:first_name',
        defaultValue: '',
        placeholder: 'coAlarm:form:first_name_placeholder',
        helpText: ''
    },
    lastName: {
        name: 'last_name',
        label: 'coAlarm:form:last_name',
        defaultValue: '',
        placeholder: 'coAlarm:form:last_name_placeholder',
        helpText: ''
    },
    country: {
        name: 'country',
        label: 'coAlarm:form:country',
        defaultValue: [],
        placeholder: 'coAlarm:form:country_placeholder',
        helpText: 'coAlarm:form:country_help_text'
    },
    phone: {
        name: 'phone',
        label: 'coAlarm:form:phone',
        defaultValue: '',
        placeholder: 'coAlarm:form:phone_placeholder',
        helpText: ''
    },
    address: {
        name: 'address',
        label: 'coAlarm:form:address',
        defaultValue: [],
        placeholder: 'coAlarm:form:address_placeholder',
        helpText: ''
    },
    addressExtras: {
        name: 'address_extras',
        label: 'coAlarm:form:address_extras',
        defaultValue: '',
        placeholder: '',
        helpText: ''
    }
} as const

// Helper function to validate phone numbers
const validateInternationalPhone = (phone: string): boolean => {
    // Remove non-numeric characters (except the leading + if present)
    const cleanedPhone = phone.replace(/(?!^\+)\D/g, '')
    // Validate based on length
    return cleanedPhone.length >= 11 && cleanedPhone.length <= 13
}

export const CoAlarmSendRequestSchema = z.object({
    sensors_requested: z.number(),
    listing: z.object({
        label: z.string(),
        value: z.string()
    }),
    first_name: z.string().min(1, {message: 'coAlarm:form:first_name_error'}),
    last_name: z.string().min(1, {message: 'coAlarm:form:last_name_error'}),
    country: z.object({
        label: z.unknown(),
        value: z.string(),
        code: z.string()
    }),
    address: z
        .object({
            label: z.string(),
            value: z.unknown()
        })
        .optional(),
    manual_address: z.boolean(),
    address_extras: z.string().nullish(),
    phone: z.string().refine(phone => validateInternationalPhone(phone), {message: 'coAlarm:form:phone_error'})
})

export type CoAlarmSendRequestSchema = z.infer<typeof CoAlarmSendRequestSchema>
