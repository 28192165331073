import Button from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {XCloseIcon} from '@/components/ui/icon'
import {ModalBody, ModalFooter, ModalHeader, ModalParagraph, ModalTitle} from '@/components/ui/modal-atoms/ModalAtoms'
import {Modal} from '@/components/ui/modal/Modal'
import {Trans, useTranslation} from 'react-i18next'
import {StyledStepNumber, StyledStepTitle} from '@/features/co-alarm/components/existing-co-alarm-modal/style'
import {EXISTING_CO_ALARM_STEPS} from '@/features/co-alarm/utils'

export const ExistingCoAlarmModal = ({hideModal}: {hideModal: () => void}) => {
    const {t} = useTranslation()

    return (
        <Modal width={438} onOverlayClick={() => hideModal()}>
            <ModalHeader>
                <Button iconOnly size="sm" variant="tertiary" onClick={() => hideModal()}>
                    <XCloseIcon />
                </Button>
            </ModalHeader>
            <ModalBody>
                <Flexbox direction="column" gap={2}>
                    <ModalTitle>{t('coAlarm:existing_co_alarm_modal:title')}</ModalTitle>
                    <ModalParagraph>{t('coAlarm:existing_co_alarm_modal:subtitle')}</ModalParagraph>
                </Flexbox>
                <Flexbox direction="column" gap={6}>
                    {EXISTING_CO_ALARM_STEPS.map((step, index) => (
                        <Flexbox key={index} gap={3}>
                            <StyledStepNumber align="center" justify="center">
                                {index + 1}
                            </StyledStepNumber>
                            <StyledStepTitle>
                                {step.link ? (
                                    <Trans i18nKey={step.title} components={[<a href={step.link} target="_blank" />]} />
                                ) : (
                                    t(step.title)
                                )}
                            </StyledStepTitle>
                        </Flexbox>
                    ))}
                </Flexbox>
            </ModalBody>
            <ModalFooter justify="center">
                <Button variant="tertiary" onClick={() => hideModal()}>
                    {t('commons:close')}
                </Button>
            </ModalFooter>
        </Modal>
    )
}
