import dayjs from 'dayjs'
import {FC, useCallback, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Calendar} from '@/components/commons/calendar/Calendar'
import {useSendHostDates} from '../../services/queries/useSendHostDates'
import {
    StyledHostDatePickerHeader,
    StyledHostDatePickerSelected,
    StyledHostDatePickerGrid,
    StyledHostDatePickerCalendarWrapper,
    StyledHostDatePickerFooter
} from './style'
import {MAX_DATE, MAX_SELECTED_LENGTH, MIN_DATE, addTimeToDate, findIndexOnSelectedDate, isDateSelected} from './utils'
import {HostDatePickerAside} from './HostDatePickerAside'
import {HostDates, ShareHostDatesStep} from './types'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'
import toast from 'react-hot-toast'
import {AxiosError} from 'axios'
import {ErrorResponseData, ServiceType} from '@/types/commons'
import {useShareHostDatesStore} from '@/features/service/store/ShareHostDatesStore'
import {HostDateChips} from './HostDateChips'
import Button from '@components/ui/button/Button.tsx'
import Spinner from '@components/ui/spinner/Spinner.tsx'

interface HostDatePickerProps {
    defaultSelectedDates: HostDates
    urlParams: ServiceDetailsURLParams
    serviceType: ServiceType
}

export const HostDatePicker: FC<HostDatePickerProps> = ({urlParams, serviceType, defaultSelectedDates}) => {
    const {t} = useTranslation()
    const [currentDate, setCurrentDate] = useState<Date>(MIN_DATE)
    const [selectedDates, setSelectedDates] = useState<HostDates>(defaultSelectedDates)
    const changeShareHostDateStep = useShareHostDatesStore(state => state.changeStep)
    const toggleShareHostDatesModal = useShareHostDatesStore(state => state.toggleModal)
    const {mutate: sendHostDates, isPending} = useSendHostDates({
        onSuccess: () => {
            changeShareHostDateStep(ShareHostDatesStep.Success)
        },
        onError: (error: AxiosError<ErrorResponseData>) => {
            const errorMessage = error.response?.data.message
            toast.error(
                errorMessage ? t(`service_details:host_dates_picker:errors:${errorMessage}`) : t('errors:default')
            )
        }
    })
    const isTouched =
        !dayjs(dayjs(defaultSelectedDates[0]).toString()).isSame(dayjs(selectedDates[0]).toString()) ||
        !dayjs(dayjs(defaultSelectedDates[1]).toString()).isSame(dayjs(selectedDates[1]).toString())

    // Set current date on calendar
    const updateCurrentDateCb = useCallback(
        (inputCurrentDate: Date) => {
            if (isDateSelected(inputCurrentDate, selectedDates)) {
                const targetIndex = findIndexOnSelectedDate(inputCurrentDate, selectedDates)
                setCurrentDate(selectedDates[targetIndex])
            } else {
                setCurrentDate(inputCurrentDate)
            }
        },
        [selectedDates]
    )

    // Add time to the current date and update the selected dates state
    const updateSelectedDatesCb = useCallback(
        (timeSelected: number) => {
            const cloneSelectedDates: HostDates = [...selectedDates]
            const dateTime: Date = addTimeToDate(currentDate, timeSelected)
            switch (selectedDates.length) {
                case 0: {
                    setSelectedDates([dateTime])
                    break
                }
                case 1: {
                    if (isDateSelected(dateTime, selectedDates)) {
                        const targetIndex = findIndexOnSelectedDate(dateTime, selectedDates)
                        cloneSelectedDates[targetIndex] = dateTime
                        setSelectedDates(cloneSelectedDates)
                    } else {
                        setSelectedDates(
                            [...cloneSelectedDates, dateTime].sort((a, b) => a.getTime() - b.getTime()) as HostDates
                        )
                    }
                    break
                }
                default: {
                    const targetIndex = findIndexOnSelectedDate(dateTime, selectedDates)
                    cloneSelectedDates[targetIndex] = dateTime
                    setSelectedDates(() => cloneSelectedDates.sort((a, b) => a.getTime() - b.getTime()))
                }
            }
        },
        [currentDate, selectedDates]
    )

    // Remove date
    const removeDate = useCallback(
        (index: number) => {
            setSelectedDates(prev => prev.filter((_, idx) => idx !== index) as HostDates)
        },
        [selectedDates]
    )

    // Submit data
    const onSubmit = useCallback(
        (selectedDates: Date[]) => {
            const remappedDates = selectedDates.map(date => dayjs(date).format('YYYY-MM-DD HH:mm:ss'))
            sendHostDates({
                urlParams,
                params: {
                    host_availability_dates: remappedDates
                }
            })
        },
        [selectedDates]
    )

    return (
        <>
            <StyledHostDatePickerHeader direction="column" gap={3}>
                <h2>{t(`service_details:host_dates_picker:${serviceType}:title`)}</h2>
                <p>{t(`service_details:host_dates_picker:${serviceType}:description`)}</p>
            </StyledHostDatePickerHeader>

            <StyledHostDatePickerGrid>
                <StyledHostDatePickerSelected align="center" gap={2}>
                    {selectedDates.length <= 0 ? (
                        <p>{t('service_details:host_dates_picker:no_date')}</p>
                    ) : (
                        <>
                            <p>
                                {t('service_details:host_dates_picker:x_of_y_selected', {
                                    selectedCount: selectedDates.length,
                                    totalCount: MAX_SELECTED_LENGTH
                                })}
                            </p>

                            <HostDateChips
                                selectedDates={selectedDates}
                                removeDate={(index: number) => removeDate(index)}
                            />
                        </>
                    )}
                </StyledHostDatePickerSelected>

                <StyledHostDatePickerCalendarWrapper>
                    <Calendar
                        numMonths={1}
                        minDate={MIN_DATE}
                        maxDate={MAX_DATE}
                        mode={'multiple'}
                        currentDate={currentDate}
                        selectedDates={selectedDates}
                        onDayClickCb={date => updateCurrentDateCb(date.$date)}
                    />
                </StyledHostDatePickerCalendarWrapper>

                <HostDatePickerAside
                    selectedDates={selectedDates}
                    currentDate={currentDate}
                    updateSelectedDatesCb={updateSelectedDatesCb}
                />
            </StyledHostDatePickerGrid>

            <StyledHostDatePickerFooter justify="space-between">
                <Button variant="tertiary" disabled={isPending} onClick={() => toggleShareHostDatesModal()}>
                    {t('service_details:host_dates_picker:pick_later')}
                </Button>
                <Button
                    variant="primary"
                    disabled={selectedDates.length != 2 || isPending || !isTouched}
                    onClick={() => onSubmit(selectedDates)}
                >
                    {isPending ? (
                        <>
                            {t('service_details:host_dates_picker:pending_submit')}
                            <Spinner size={20} />
                        </>
                    ) : (
                        t('commons:submit')
                    )}
                </Button>
            </StyledHostDatePickerFooter>
        </>
    )
}
