import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {
    ListingThumbnailImage,
    ListingThumbnailName
} from '@/components/ui/listing-thumbnail-atoms/ListingThumbnailAtoms'

export const CoAlarmListingLabel = ({imageUrl, name}: {imageUrl: string | null; name: string}) => {
    return (
        <Flexbox gap={2} align="center" width="100%">
            <ListingThumbnailImage src={imageUrl} />
            <ListingThumbnailName>{name}</ListingThumbnailName>
        </Flexbox>
    )
}
