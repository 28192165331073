import Button from '@/components/ui/button/Button'
import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {
    StyledCoManualAddressFormFooter,
    StyledManualAddressFormContainer,
    StyledManualAddressFormHeader,
    StyledManualAddressLocationWrapper
} from '@/features/co-alarm/components/co-alarm-send-request-form/style'
import {SubmitHandler, useForm} from 'react-hook-form'
import {
    MANUAL_ADDRESS_FORM_MODEL,
    ManualAddressFormSchema
} from '@/features/co-alarm/components/co-alarm-send-request-form/manual-address-form/CoManualAddressFormModel'
import {zodResolver} from '@hookform/resolvers/zod'
import InputText from '@/components/commons/input-text/InputText'

interface CoManualAddressFormProps {
    closeModal: () => void
    onSetManualAddress: (value: ManualAddressFormSchema) => void
}

export const CoManualAddressForm: FC<CoManualAddressFormProps> = ({closeModal, onSetManualAddress}) => {
    const {t} = useTranslation()
    // hook form setup
    const {
        register,
        handleSubmit,
        getValues,
        //setValue,
        formState: {errors, isSubmitting, isValid}
    } = useForm<ManualAddressFormSchema>({
        mode: 'onBlur',
        shouldFocusError: true,
        resolver: zodResolver(ManualAddressFormSchema)
    })

    const onSubmit: SubmitHandler<ManualAddressFormSchema> = data => {
        onSetManualAddress(data)
        closeModal()
    }

    return (
        <>
            <StyledManualAddressFormHeader align="center">
                <h3>{t('coAlarm:smokeForm:manual_address_modal:title')}</h3>
            </StyledManualAddressFormHeader>

            <form onSubmit={handleSubmit(onSubmit)}>
                <StyledManualAddressFormContainer direction="column" width={'100%'} gap={4} align="stretch">
                    <StyledManualAddressLocationWrapper>
                        <InputText
                            label={t(MANUAL_ADDRESS_FORM_MODEL.firstAddressLine.label)}
                            type={'text'}
                            errorMessage={t(errors.address_line_1?.message || '')}
                            placeholder={t(MANUAL_ADDRESS_FORM_MODEL.firstAddressLine.placeholder).toString()}
                            {...register(t(MANUAL_ADDRESS_FORM_MODEL.firstAddressLine.name))}
                        />
                        <InputText
                            label={t(MANUAL_ADDRESS_FORM_MODEL.streetNumber.label)}
                            type={'text'}
                            errorMessage={t(errors.street_number?.message || '')}
                            placeholder={t(MANUAL_ADDRESS_FORM_MODEL.streetNumber.placeholder).toString()}
                            {...register(t(MANUAL_ADDRESS_FORM_MODEL.streetNumber.name))}
                        />
                    </StyledManualAddressLocationWrapper>
                    <InputText
                        label={t(MANUAL_ADDRESS_FORM_MODEL.secondAddressLine.label)}
                        type={'text'}
                        errorMessage={t(errors.address_line_2?.message || '')}
                        placeholder={t(MANUAL_ADDRESS_FORM_MODEL.secondAddressLine.placeholder).toString()}
                        {...register(t(MANUAL_ADDRESS_FORM_MODEL.secondAddressLine.name))}
                    />
                    <InputText
                        label={t(MANUAL_ADDRESS_FORM_MODEL.thirdAddressLine.label)}
                        type={'text'}
                        errorMessage={t(errors.address_line_3?.message || '')}
                        placeholder={t(MANUAL_ADDRESS_FORM_MODEL.thirdAddressLine.placeholder).toString()}
                        {...register(t(MANUAL_ADDRESS_FORM_MODEL.thirdAddressLine.name))}
                    />
                    <InputText
                        label={t(MANUAL_ADDRESS_FORM_MODEL.fourthAddressLine.label)}
                        type={'text'}
                        errorMessage={t(errors.address_line_4?.message || '')}
                        placeholder={t(MANUAL_ADDRESS_FORM_MODEL.fourthAddressLine.placeholder).toString()}
                        {...register(t(MANUAL_ADDRESS_FORM_MODEL.fourthAddressLine.name))}
                    />
                    <InputText
                        label={t(MANUAL_ADDRESS_FORM_MODEL.city.label)}
                        type={'text'}
                        errorMessage={t(errors.city?.message || '')}
                        placeholder={t(MANUAL_ADDRESS_FORM_MODEL.city.placeholder).toString()}
                        {...register(t(MANUAL_ADDRESS_FORM_MODEL.city.name))}
                    />
                    <InputText
                        label={t(MANUAL_ADDRESS_FORM_MODEL.state.label)}
                        type={'text'}
                        errorMessage={t(errors.state?.message || '')}
                        placeholder={t(MANUAL_ADDRESS_FORM_MODEL.state.placeholder).toString()}
                        {...register(t(MANUAL_ADDRESS_FORM_MODEL.state.name))}
                    />
                    <InputText
                        label={t(MANUAL_ADDRESS_FORM_MODEL.postalCode.label)}
                        type={'text'}
                        errorMessage={t(errors.postal_code?.message || '')}
                        placeholder={t(MANUAL_ADDRESS_FORM_MODEL.postalCode.placeholder).toString()}
                        {...register(t(MANUAL_ADDRESS_FORM_MODEL.postalCode.name))}
                    />
                </StyledManualAddressFormContainer>
            </form>
            <StyledCoManualAddressFormFooter justify="space-between">
                <Button variant="tertiary" onClick={() => closeModal()}>
                    {t('commons:close')}
                </Button>
                <Button
                    variant={'primary'}
                    size={'md'}
                    disabled={!isValid || isSubmitting}
                    onClick={() => onSubmit(getValues())}
                >
                    {t('commons:confirm')}
                </Button>
            </StyledCoManualAddressFormFooter>
        </>
    )
}
