import {UseInfiniteQueryOptions} from '@tanstack/react-query'
import {useInputSearch} from '@/hooks/useInputSearch'
import {HttpGetCoAlarmListingsResponse} from '@/features/co-alarm/services/coAlarm.http'
import {useGetCoAlarmListings} from './useGetCoAlarmListings'

export const useCoAlarmListingsTable = (
    options?: Omit<UseInfiniteQueryOptions<HttpGetCoAlarmListingsResponse>, 'queryFn' | 'queryKey'>
) => {
    const {searchValue, searchError, onSearch, onResetSearch} = useInputSearch()

    const coAlarmListingsQuery = useGetCoAlarmListings({
        params: {
            limit: 10,
            response_type: 'extended',
            filter_by: 'co_alarm_status',
            filter_values: ['delivered', 'eligible', 'installed', 'requested', 'shipped', 'to_install'],
            ...(searchValue && {search: searchValue})
        }
    })

    return {
        ...options,
        ...coAlarmListingsQuery,
        searchValue,
        searchError,
        onSearch,
        onResetSearch
    }
}
