import styled, {css, DefaultTheme} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {StepCardContentDirection, StepStatus} from '@/components/commons/step-card/StepCard'

const stepCardVariants = ({
    theme: {palette, typography, shadows, mediaQueries},
    stepCardContentDirection
}: {
    theme: DefaultTheme
    stepCardContentDirection: StepCardContentDirection
}) =>
    ({
        active: css`
            .number {
                background: ${palette.primary['600']};
                font-size: ${typography.textMd};
                font-weight: 700;
                color: ${palette.neutral.white};
                box-shadow: ${shadows.md};
            }

            h1 {
                color: ${palette.neutral['900']};
            }

            ${stepCardContentDirection == 'row'
                ? css`
                      background: transparent;
                      border: none;
                  `
                : css`
                      background: ${palette.primary['50']};
                      border: 1px solid ${palette.primary['300']};

                      ${mediaQueries.m} {
                          background: transparent;
                          border: none;
                      }
                  `}
        `,
        completed: css`
            .number {
                background: ${palette.success['50']};
                color: ${palette.success['600']};
            }

            h1 {
                color: ${palette.neutral['700']};
            }
        `,
        error: css`
            .number {
                background: ${palette.danger['600']};
                color: ${palette.neutral.white};
            }

            h1 {
                color: ${palette.neutral['900']};
            }
        `,
        inactive: css`
            .number {
                background: ${palette.neutral['100']};
                color: ${palette.neutral['500']};
            }

            h1 {
                color: ${palette.neutral['500']};
            }
        `
    }) satisfies Record<StepStatus, ReturnType<typeof css>>

const stepCardContentDirectionVariant = ({theme: {spacing, mediaQueries}}: {theme: DefaultTheme}) =>
    ({
        row: css`
            flex-direction: row;
            align-items: center;
            gap: ${spacing * 4}px;
        `,
        column: css`
            flex-direction: column;
            align-items: flex-start;
            gap: ${spacing * 4}px;

            ${mediaQueries.m} {
                flex-direction: row;
            }
        `
    }) satisfies Record<'row' | 'column', ReturnType<typeof css>>

export const StyledStepCard = styled(Flexbox)(
    ({
        status,
        stepCardContentDirection = 'column',
        theme
    }: {
        status: StepStatus
        stepCardContentDirection?: StepCardContentDirection
        theme: DefaultTheme
    }) => css`
        border-radius: 12px;

        .number {
            background: tan;
            border-radius: 50%;
            font-weight: 700;
            flex-shrink: 0;
        }

        h1 {
            font-size: ${theme.typography.textSm};
            font-weight: 600;
        }

        h2 {
            font-size: ${theme.typography.textSm};
            color: ${theme.palette.neutral['600']};
        }

        ${theme.mediaQueries.m} {
            border-radius: 0;
            gap: ${theme.spacing * 2}px;
        }

        ${stepCardContentDirectionVariant({theme})[stepCardContentDirection]}
        ${stepCardVariants({theme, stepCardContentDirection})[status]}
    `
)
