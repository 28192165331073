import styled, {css} from 'styled-components'
import {Scrollbar} from '@components/ui/scrollbar/Scrollbar.tsx'
import Container from '@/components/ui/container/Container'

export const StyledStepsContainer = styled(Container)(
    ({theme: {mediaQueries}}) => css`
        ${mediaQueries.m} {
            padding: 0;
        }
    `
)

export const StyledStepsWrapper = styled('div')<{$hasShadow: boolean; $hasBorder: boolean}>(
    ({theme: {shadows, palette, mediaQueries}, $hasShadow, $hasBorder}) => css`
        background: ${palette.neutral.white};
        border-radius: 12px;

        ${$hasShadow &&
        css`
            box-shadow: ${shadows.lg};
        `}
        ${$hasBorder &&
        css`
            border: 1px solid ${palette.neutral[300]};
        `}

        ${mediaQueries.m} {
            border-radius: 0;
        }
    `
)

export const StyledSteps = styled('div')<{$stepsCount: number; $hasPaddingY: boolean}>(
    ({theme: {spacing, mediaQueries}, $stepsCount, $hasPaddingY}) => css`
        display: grid;
        grid-template-columns: repeat(${$stepsCount}, minmax(max-content, 1fr));
        gap: ${spacing * 14}px;
        ${mediaQueries.xl} {
            gap: ${spacing * 8}px;
        }

        ${mediaQueries.m} {
            gap: ${spacing * 4}px;
        }

        ${$hasPaddingY
            ? css`
                  padding: ${spacing * 4}px;

                  ${mediaQueries.m} {
                      padding: 0 ${spacing * 4}px;
                  }
              `
            : css`
                  padding: 0 ${spacing * 4}px;
              `}
    `
)

export const StyledScrollbar = styled(Scrollbar)(
    ({theme: {spacing, mediaQueries}}) => css`
        margin: 0 ${spacing * 3}px ${spacing * 0.5}px;

        ${mediaQueries.m} {
            margin: 0 0 -${spacing * 2}px;
        }
    `
)
