import {ActionCard} from '@/features/service-actions/components/action-card/ActionCard.tsx'
import {CheckDone01Icon} from '@components/ui/icon'
import {Trans, useTranslation} from 'react-i18next'
import {FC, useState} from 'react'
import {useOutletContext} from 'react-router-dom'
import {ServiceDetailsURLParams} from '@route-guards/service-route/ServiceRoute.tsx'
import {httpClickAction, httpCompleteAction} from '@/features/service-actions/services/actions.http.ts'
import {Modal} from '@components/ui/modal/Modal.tsx'
import {ServiceAction} from '@/features/service-actions/types.ts'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import Button from '@components/ui/button/Button.tsx'
import {
    StyledImportantVideoInfoModalBody,
    StyledImportantVideoInfoModalFooter
} from '@/features/service-actions/components/important-video-info-action/style.ts'
import {useAsync} from '@hooks/useAsync.ts'
import {errorHandler} from '@utilities/helpers.ts'
import Spinner from '@components/ui/spinner/Spinner.tsx'

export const ImportantVideoInfoAction: FC<{actionId: ServiceAction['id']; isCompleted: boolean}> = ({
    actionId,
    isCompleted
}) => {
    const {t} = useTranslation()
    const {urlParams} = useOutletContext<{urlParams: ServiceDetailsURLParams}>()
    const [showModal, setShowModal] = useState(false)
    const completeActionApi = useAsync()

    const clickAction = () => {
        httpClickAction({actionId, ...urlParams})
        setShowModal(true)
    }

    const completeAction = async () => {
        try {
            await completeActionApi.run(httpCompleteAction({actionId, ...urlParams}))
            setShowModal(false)
        } catch (error) {
            errorHandler(error)
        }
    }

    return (
        <>
            <ActionCard
                icon={CheckDone01Icon}
                isCompleted={isCompleted || completeActionApi.isSuccess}
                onClick={isCompleted || completeActionApi.isSuccess ? undefined : clickAction}
                paragraph={t('service_details:actions:important_video_info:paragraph')}
                title={t('service_details:actions:important_video_info:title')}
            />
            {showModal && (
                <Modal width={480}>
                    <StyledImportantVideoInfoModalBody direction="column" padding={6} gap={6}>
                        <Flexbox direction="column" gap={2}>
                            <h2>{t('service_details:actions:important_video_info:title')}</h2>
                            <p>{t('service_details:actions:important_video_info:paragraph')}</p>
                        </Flexbox>
                        <Flexbox direction="column" gap={1}>
                            <h3>{t('service_details:actions:important_video_info:task1_title')}</h3>
                            <p>{t('service_details:actions:important_video_info:task1_paragraph')}</p>
                        </Flexbox>
                        <Flexbox direction="column" gap={1}>
                            <h3>{t('service_details:actions:important_video_info:task2_title')}</h3>
                            <p>
                                <Trans
                                    components={{
                                        a: (
                                            <a
                                                href="https://airbnb.box.com/s/nu64r7ieci81s2oe05b4o2a14rjg6om9"
                                                target="_blank"
                                                rel="noreferrer"
                                            />
                                        )
                                    }}
                                    i18nKey={t('service_details:actions:important_video_info:task2_paragraph')}
                                />
                            </p>
                        </Flexbox>
                        <Flexbox direction="column" gap={1}>
                            <h3>{t('service_details:actions:important_video_info:task3_title')}</h3>
                            <p>{t('service_details:actions:important_video_info:task3_paragraph')}</p>
                        </Flexbox>
                    </StyledImportantVideoInfoModalBody>
                    <StyledImportantVideoInfoModalFooter justify="space-between" gap={4}>
                        <Button variant="ghost" onClick={() => setShowModal(false)}>
                            {t('service_details:actions:important_video_info:cta1')}
                        </Button>
                        <Button onClick={completeAction} disabled={completeActionApi.isLoading}>
                            {t('service_details:actions:important_video_info:cta2')}
                            {completeActionApi.isLoading && <Spinner size={20} />}
                        </Button>
                    </StyledImportantVideoInfoModalFooter>
                </Modal>
            )}
        </>
    )
}
