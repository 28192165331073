import {ButtonHTMLAttributes, HTMLAttributes} from 'react'
import {
    StyledModalBody,
    StyledModalFooter,
    StyledModalHeader,
    StyledModalParagraph,
    StyledModalTitle
} from '@/components/ui/modal-atoms/style'
import Button from '@/components/ui/button/Button'
import {XCloseIcon} from '@/components/ui/icon/index'
import {FlexBoxCustomProps} from '@/components/ui/flexbox/FlexBox'

export const ModalHeader = (props: HTMLAttributes<HTMLDivElement>) => <StyledModalHeader {...props} />
export const ModalBody = (props: HTMLAttributes<HTMLDivElement> & FlexBoxCustomProps) => (
    <StyledModalBody direction={props.direction ?? 'column'} {...props} />
)
export const ModalFooter = (props: HTMLAttributes<HTMLDivElement> & FlexBoxCustomProps) => (
    <StyledModalFooter {...props} />
)
export const ModalTitle = (props: HTMLAttributes<HTMLHeadingElement>) => <StyledModalTitle {...props} />
export const ModalParagraph = (props: HTMLAttributes<HTMLParagraphElement>) => <StyledModalParagraph {...props} />
export const ModalXCloseButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => (
    <Button variant="ghost" size="sm" iconOnly {...props}>
        <XCloseIcon />
    </Button>
)
