import {z} from 'zod'
import {NotificationPreferences} from '@/features/notifications-settings/types.ts'

/**
 * Task schema and Types
 */
export const TaskResource = z.object({
    id: z.coerce.number(),
    name: z.string().min(1)
})
export type TaskResource = z.infer<typeof TaskResource>

export const TaskMedia = z.object({
    id: z.number(),
    name: z.enum(['photo', 'video', 'matterport', 'walkthrough'])
})
export type TaskMedia = z.infer<typeof TaskMedia>

export const TaskStatusValue = z.enum([
    'accepted',
    'assets_in_review',
    'auto_cancelled',
    'completed',
    'hard_cancelled',
    'needs_more_assets',
    'overdue',
    'pending',
    'pending_assets',
    'pending_payment',
    'scheduled',
    'soft_cancelled',
    'waitlisted'
])
export type TaskStatusValue = z.infer<typeof TaskStatusValue>

export const CoordinationStatus = z.enum([
    'cancellation_requested',
    'dates_dont_match',
    'photographer_not_available',
    'host_contacted',
    'host_dates_shared',
    'host_not_ready',
    'host_unresponsive',
    'manual_coordination',
    'overdue_host_contacted'
])

export const TaskStatus = z.object({
    status: TaskStatusValue,
    post_prod_status: z
        .enum([
            'initial_qa_failed',
            'needs_to_be_assigned',
            'final_qa_failed',
            'assigned',
            'initial_qa',
            'work_in_progress',
            'ready_for_final_qa',
            'ready_for_pre_upload',
            'ready_for_upload',
            'upload_in_progress',
            'upload_issue',
            'upload_completed'
        ])
        .nullable(),
    coordination_status: CoordinationStatus.nullable()
})
export type TaskStatus = z.infer<typeof TaskStatus>

export const Task = z.object({
    created_at: z.coerce.date().nullable(),
    status_id: z.coerce.number(),
    task_status: TaskStatus
})
export type Task = z.infer<typeof Task>

/**
 * Quote schema and Types
 */
export const Quote = z.object({
    accepted_at: z.coerce.date().nullable(),
    amount: z.number().nullable(),
    can_cancel: z.boolean(),
    can_see_sms_settings: z.boolean(),
    created_at: z.coerce.date().nullable(),
    email_for_notifications: z.string().email().nullish(),
    expire_at: z.coerce.date(),
    external_host_id: z.string().nullish(),
    host_portrait: z.coerce.boolean().nullable(),
    identifier: z.string().nullish(),
    id: z.number(),
    listingImgUrl: z.string().url().nullish(),
    next_reminder_at: z.coerce.date().nullable(),
    notification_preferences: NotificationPreferences.nullish(),
    phone_for_notifications: z.string().nullish(),
    photo_count: z.number().nullable(),
    raw_satisfaction: z.string().nullable(),
    reminder_at: z.coerce.date().nullable(),
    quote_status: z.enum(['cancelled', 'completed', 'declined', 'expired', 'renewed', 'sent', 'signed']),
    send_notifications_to_email: z.boolean().nullable(),
    send_notifications_to_phone: z.boolean().nullable(),
    task: z.object({...Task.shape}).nullish()
})
export type Quote = z.infer<typeof Quote>

export const HostAvailabilityDate = z.object({
    starting_time: z.coerce.date()
})
export type HostAvailabilityDate = z.infer<typeof HostAvailabilityDate>

/**
 * Assignment schema and Types
 */
export const Assignment = z.object({
    //TODO: cancellation_requested_at: z.coerce.date().nullish() must be cancellation_requested_at: z.coerce.date().nullable(),
    cancellation_requested_at: z.coerce.date().nullish(),
    manual_coordination_at: z.coerce.date().nullable(),
    photographer: z
        .object({
            description: z.string().nullable(),
            email: z.string().email(),
            first_name: z.string().min(1),
            profile_photo_url: z.string().url().nullable(),
            name: z.string().min(1),
            phone: z.string().nullable()
        })
        .nullable(),
    reminder_at: z.coerce.date().nullable(),
    status: z.enum(['accepted', 'auto_cancelled', 'declined', 'hard_cancelled', 'pending', 'soft_cancelled']),
    task_status: TaskStatus,
    to_shoot_at: z.coerce.date().nullable()
})
export type Assignment = z.infer<typeof Assignment>

/**
 * Entity schema and Types
 */
export const Entity = z.object({
    bedrooms: z.number().int().nullable(),
    city: z.string().nullable(),
    code: z.string().nullable(),
    country: z.string().min(1),
    external_id: z.string(),
    entity_type_id: z.number().nullish(),
    entity_type_name: z.string().nullish(),
    airbnb_link: z.string().url().nullable(),
    market: z
        .object({
            geo: z.string()
        })
        .nullish(),
    region: z.string().nullish(),
    room_type: z.string().nullish(),
    space_type: z.string().nullish(),
    password: z.string().nullish(),
    title: z.string().nullable()
})
export type Entity = z.infer<typeof Entity>

export const EntityMeta = z.array(z.object({attribute: z.string().min(1), value: z.string().min(1)}))
export type EntityMeta = z.infer<typeof EntityMeta>

/**
 * Host schema and Types
 */
export const Host = z.object({
    email: z.string().email(),
    first_name: z.string().min(1),
    phone: z.string().nullable()
})
export type Host = z.infer<typeof Host>

/**
 * Matterport schema and Types
 */
export const TaskMatterport = z.object({
    id: z.coerce.number(),
    status: z.enum([
        'upload_to_mediabox',
        'ready_for_QA',
        'pending_host_approval',
        'matterport_revision',
        'ready_for_upload',
        'completed'
    ])
})
export type TaskMatterport = z.infer<typeof TaskMatterport>
